import { Add } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import CookieBanner from '../../components/CookieBanner/CookieBanner'
import PostHomeCard from '../../components/PostHomeCard/PostHomeCard'
import { AppLayout } from '../../Layout/AppLayout'
import { commonTranslations } from '../../translation/commonTranslations'
import { countries } from '../../utilities/countries'
import { updateUserProfile } from '../SignupPage/sagas/saga'
import { fetchLatestPosts, fetchPrelovedPosts } from './saga/saga'

export default function HomePage() {
  const { country = '', language = '' } = useParams();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false)
  const user = useSelector(state => state.user.user)
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`
  const history = useHistory()
  // latest post
  const [latestPost, setLatestPost] = useState([])
  const [latestPostLoading, setLatestPostLoading] = useState(false)

  // preloved posts
  const [prelovedPosts, setPrelovedPosts] = useState([])
  const [prelovedPostLoading, setPrelovedPostLoading] = useState(false)

  useEffect(() => {
    if (!countries.find(item => item.code.toLowerCase() === country)?.code || language !== 'en') {
      history.push('/')
      return
    }
    _fetchLatestPosts()
    _fetchPreLovedPosts()
  }, [])

  useEffect(() => {
    if (isProfileUpdated) return
    if (user?.id) {
      updateProfile()
    }
  }, [user])
  
  const updateProfile = () => {
    updateUserProfile({ userId: user?.id, userData: { country }, notification: false, noUpdate: true })
    setIsProfileUpdated(true)
  }

  const _fetchLatestPosts = () => {
    setLatestPostLoading(true)
    const data = []
    fetchLatestPosts({ userCountry: country }).then(res => {
      res.forEach(item => {
        data.push({ ...item.data(), id: item.id })
      })
    }).then(() => {
      setLatestPostLoading(false)
      setLatestPost(data)
    }).catch(() => {
      setLatestPostLoading(false)
    })
  }
  const _fetchPreLovedPosts = () => {
    setPrelovedPostLoading(true)
    const data = []
    fetchPrelovedPosts({ userCountry: country }).then(res => {
      res.forEach(item => {
        data.push({ ...item.data(), id: item.id })
      })
    }).then(() => {
      setPrelovedPostLoading(false)
      setPrelovedPosts(data)
    }).catch(() => {
      setPrelovedPostLoading(false)
    })
  }
  return (
    <AppLayout selected={0}>
      <Helmet>
        <title>Buy sell dresez | dresez.com</title>
      </Helmet>
      <div className='w-full flex items-center flex-col'>
        <div className='max-w-[1400px] w-full px-2'>
          <div className='w-full py-[1rem]'>
            <center><h1 className='my-4'>Showcasing top designers and brands across the globe!</h1></center>
            {(!latestPostLoading && latestPost.length > 0) ? latestPost.length > 0 ? <div>
              <div className='flex justify-between items-center'>
                <p className='text-[22px] font-bold'>{commonTranslations.latest}</p>
                {latestPost.length >= 10 &&
                  <Link to={navTo('latest')} className='text-[16px] cursor-pointer text-gray-600'>{commonTranslations.viewAll}</Link>}
              </div>
              {
                latestPost.length > 0 ?
                <div className='flex w-full overflow-x-auto gap-4 scroll-bar-style pb-4'>
                  {latestPost?.map((item, i) => <Link to={navTo(`post/${item?.id}`)}><PostHomeCard description={item?.description} id={item.id} image={item?.images?.[0]} key={i} title={item?.title} condition={item?.condition} /></Link>)}
                </div> : 
                <Link to={navTo('add-post')}>
                  <div className='flex justify-between items-center mt-4'>
                   <div style={{ border: `1px solid rgb(131,56,236)` }} className="text-[#8338EC] font-bold cursor-pointer rounded p-2 flex items-center w-max">
                      <Add /> &nbsp;
                      Add Post
                  </div>
                  </div>
                  </Link>
              }
            </div> : prelovedPosts.length === 0 && <div className='flex justify-center items-center w-full h-full mt-[50px]'>
              <p className='text-center text-[20px]'>{commonTranslations.nobodyPostedWhile} <Link to={navTo('add-post')} className='text-[#0000EE] cursor-pointer'>{commonTranslations.post}</Link></p>
              </div>
               : prelovedPosts.length === 0 && <div className='flex justify-center items-center w-full h-full mt-[50px]'>
              <p className='text-center text-[20px]'>{commonTranslations.nobodyPostedWhile} <Link to={navTo('add-post')} className='text-[#0000EE] cursor-pointer'>{commonTranslations.post}</Link></p>
              </div>
              }

            {!prelovedPostLoading && prelovedPosts.length > 0 &&
              <div className='mt-8'>
                <p className='text-[22px] font-bold'>{commonTranslations.preloved}</p>
                {
                  prelovedPosts.length > 0 &&
                  <div className='flex w-full overflow-x-auto gap-4 scroll-bar-style pb-4'>
                    {prelovedPosts?.map((item, i) => <Link to={navTo(`post/${item?.id}`)}><PostHomeCard description={item?.description} id={item.id} image={item?.images?.[0]} key={i} title={item?.title} condition={item?.condition} /></Link>)}
                  </div>
                }
              </div>}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
