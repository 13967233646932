import { Button, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { languages } from '../../utilities/languages'
import { userCountry, userLanguage } from '../../utilities/random'

export default function LanguageBar() {
  const [openDropdown, setOpenDropdown] = useState(false)
    const AllLanguages = [
      // ...languages.find(item => item.alpha2Code.toLowerCase() === userCountry.toLowerCase()).languages,
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ]
    const [selectedLanguage, setSelectedLanguage] = useState(languages.find(item => item.alpha2Code.toLowerCase() === userCountry.toLowerCase())?.languages?.find(item => item.iso639_1.toLowerCase() === userLanguage.toLowerCase())?.name || 'English')
  const selectLanguage = (val) => {
    // fetchTranslation(val.toLowerCase())
    setOpenDropdown(false)
    setSelectedLanguage(val)
  }
  const openLanguageDropdown = () => setOpenDropdown(true)
   return (
    <div className='w-full'>
      <div className='w-full rounded border p-2 bg-white flex items-center justify-between'>
        <p>Language: <span className='font-bold'>{selectedLanguage}</span></p>

        <Button onClick={openLanguageDropdown}>Change</Button>
      </div>
      <div className='mt-[-40px]'>
    <Select
      variant='outlined'
      value={selectedLanguage}
      onChange={(e) => selectLanguage(e.target.value)}
      displayEmpty
      style={{ height: 40, backgroundColor: 'white', opacity: 0 }}
      open={openDropdown}
      onClose={() => setOpenDropdown(false)}
    >
        {AllLanguages.map((data) => <MenuItem className='flex items-center' value={data.name}><p className='text-[12px]'>{data.name}</p></MenuItem>)}
    </Select>
    </div>
    </div>
  )
}
