import produce from 'immer'
import { GET_USER_LOADING, GET_USER_SUCCESS, GET_USER_FAILED, LOGOUT_USER } from './types'

const defaultState = {
  isLoading: true,
  isError: false,
  user: {},
  error: ''
}

const userReducer = produce((state = defaultState, action) => {
  const { type, data } = action

  switch (type) {
   case LOGOUT_USER: {
       state.isLoading = false
       state.isError = false
       state.error = ''
       state.user = {}
       break;
   }
   case GET_USER_LOADING: {
       state.isLoading = true
       state.isError = false
       state.error = ''
       state.user = {}
       break;
   }
   case GET_USER_SUCCESS: {
       state.isLoading = false
       state.isError = false
       state.error = ''
       state.user = data
       break;
   }
   case GET_USER_FAILED: {
       state.isLoading = false
       state.isError = true
       state.user = {}
       state.error = data
       break;
   }
    default:
      return state
  }
}, defaultState)

export default userReducer