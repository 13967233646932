import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 } from "uuid";
import { storage, db } from "../../../utilities/firebase";
import * as fireStore from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { showNotification } from "../../../components/snackbar/saga";

export const imageUploader = ({
  path = "postImages",
  file,
  setIsloading = () => null,
  setIsError = () => null,
  setIsSuccess = () => null,
}) => {
  setIsloading(true);
  setIsError("");
  const storageRef = ref(storage, `${path}/${v4()}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
    },
    (error) => {
      setIsError(error);
      setIsloading(false);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setIsSuccess(downloadURL);
        setIsloading(false);
      });
    }
  );
};

export const addPost = ({
  title,
  description,
  gender,
  condition,
  images = [],
  userId,
  price,
  discount,
  isEdit = false,
  postId,
  hashTags,
  currencyCode,
  country,
  createdAt
}) =>
  new Promise((resolve, reject) => {
    const data = {
      title,
      description,
      gender,
      condition,
      images,
      userId,
      price,
      discount,
      status: 'APPROVED',
      notes: '',
      hashTags,
      currencyCode,
      country,
      createdAt
    };

    fireStore
      .setDoc(fireStore.doc(db, "posts",isEdit ? postId : v4()), data)
      .then(() => {
        showNotification({ message: isEdit ? "Post updated successfully!" : "Post created successfully!" });
        resolve();
      })
      .catch((e) => {
        showNotification({ isError: true, message: e.message });
        reject();
      });
  });

  export const fetchSinglePost = async ({ postId }) => {
    const docRef = doc(db, "posts", postId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
     return Promise.resolve(docSnap.data())
    } else {
      return Promise.reject("Invalid id of the post");
    }
  }