import { CircularProgress } from '@mui/material'
import React from 'react'
import { commonTranslations } from '../../translation/commonTranslations'

export default function FullPageLoading() {
  return (
    <div  style={{ height: window.innerHeight }} className="w-full items-center justify-center flex flex-col">
        <CircularProgress size={60} />
        <h2 className='mt-4'>{commonTranslations.loading}</h2>
    </div>
  )
}
