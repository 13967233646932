import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { Field } from "redux-form";
import GroupField from "../../components/GoupField/GroupField";
import { commonTranslations } from "../../translation/commonTranslations";
import { Button, CircularProgress } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { fetchUser, updateUserProfile } from "../SignupPage/sagas/saga";
import { AppLayout } from "../../Layout/AppLayout";
import { imageUploader } from "../AddPostPage/sagas/saga";
import { useRef } from "react";
import { showNotification } from "../../components/snackbar/saga";
import placeholderUserImage from "../../assets/images/placeholderUserImage.png";
import Label from "../../components/Label/Label";
import { parsePhoneNumber } from "libphonenumber-js";

export const ProfilePageEdit = injectIntl(({ handleSubmit, intl, change }) => {
  const { formatMessage } = intl;
  const imageRef = useRef();
  const { country = '' } = useParams()
  const [userImage, setUserImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneSignedIn, setIsPhoneSignedIn] = useState(false)
  const user = useSelector((state) => state.user?.user);
  const onUpdateUserProfile = (val) => {
    const phoneNumber = parsePhoneNumber(val?.contactNumber, country?.toUpperCase())
    const userData = {
      email: val?.email || "",
      name: val?.fullName || "",
      phone: phoneNumber.number || "",
      contactNumber: phoneNumber.number || "",
      userImage,
      facebook: val?.facebook || '',
      instagram: val?.instagram || '',
      twitter: val?.twitter || '',
      tiktok: val?.tiktok || '',
      description: val?.description || '',
      brandName: val?.brandName || '',
      isProfileCompleted: true,
      ...val,
   };
    setIsLoading(true);
    updateUserProfile({ userId: user?.id, userData })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  
  useEffect(() => {
    change("fullName", user?.name || "");
    change("brandName", user?.brandName || "");
    change("email", user?.email || "");
    change("contactNumber", user?.phone || user?.contactNumber || "");
    change("description", user?.description || "");
    change("facebook", user?.facebook || "");
    change("instagram", user?.instagram || "");
    change("twitter", user?.twitter || "");
    change("tiktok", user?.tiktok || "");
    setUserImage(user?.userImage || "");

    (async () => {
      const user = await fetchUser()
      setIsPhoneSignedIn(user?.phoneNumber && !user?.email)
    })()
  }, [user]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    imageUploader({
      file,
      setIsloading: (loader) => {
        if (!loader) return;
        setIsLoading(true);
      },
      setIsError: (error) => {
        if (!error) return;
        showNotification({ isError: true, message: error });
        setIsLoading(false);
      },
      setIsSuccess: (url) => {
        setUserImage(url || "");
        setIsLoading(false);
      },
      path: "users",
    });
  };

  return (
    <AppLayout back selected={2}>
      <div
        style={{ minHeight: window.innerHeight }}
        className="box bg-white py-4"
      >
        <Helmet>
          <title>Profile | dresez.com</title>
        </Helmet>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <center>
              <Typography paragraph style={{ fontWeight: "600" }} variant="h5">
                My Profile
              </Typography>
            </center>

            <form onSubmit={handleSubmit(onUpdateUserProfile)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Label title={"Profile/Brand image"} />
                  <br />
                  {!userImage && (
                    <Button
                      variant="contained"
                      onClick={() => imageRef.current.click()}
                      className='mt-2'
                    >
                      Upload Image
                    </Button>
                  )}
                  {userImage && (
                    <img
                      className="w-[150px] h-[150px] rounded-full object-cover cursor-pointer mt-2"
                      src={userImage || placeholderUserImage}
                      onClick={() => imageRef.current.click()}
                      alt={"profile"}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="fullName"
                    component={GroupField}
                    placeholder={"John Doe"}
                    label={`${formatMessage(
                      commonTranslations.fullName.props
                    )}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="brandName"
                    component={GroupField}
                    placeholder={"My Brand"}
                    label={`${formatMessage(
                      commonTranslations.brandName.props
                    )}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    component={GroupField}
                    placeholder={"johndoe@dresez.com"}
                    label={`${formatMessage(commonTranslations.email.props)}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                    disabled={!isPhoneSignedIn}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="contactNumber"
                    component={GroupField}
                    placeholder={""}
                    label={`${formatMessage(commonTranslations.contact.props)}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                    disabled={isPhoneSignedIn}
                    className="opacity-[0.3]"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="description"
                    component={GroupField}
                    label={`${formatMessage(
                      commonTranslations.description.props
                    )}`}
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={150}
                    multline
                    rows={5}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="tiktok"
                    component={GroupField}
                    placeholder={"your tiktok page url"}
                    label={`${formatMessage(
                      commonTranslations.tiktok.props
                    )}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="instagram"
                    component={GroupField}
                    placeholder={"your instagram page url"}
                    label={`${formatMessage(
                      commonTranslations.instagram.props
                    )}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="twitter"
                    component={GroupField}
                    placeholder={"your twitter page url"}
                    label={`${formatMessage(commonTranslations.twitter.props)}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="facebook"
                    component={GroupField}
                    placeholder={"your Meta/facebook page url"}
                    label={`${formatMessage(
                      commonTranslations.facebook.props
                    )}`}
                    type="text"
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <center>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                      >
                        Update
                      </Button>
                    )}
                  </center>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </div>
      <input
        accept="image/png, image/jpeg"
        ref={imageRef}
        onChange={handleFile}
        type={"file"}
        className={"hidden"}
      />
    </AppLayout>
  );
});
