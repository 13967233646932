import React from "react";
import "./styles.scss";
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { Field } from "redux-form";
import GroupField from "../../components/GoupField/GroupField";
import { commonTranslations } from "../../translation/commonTranslations";
import { Button, CircularProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { signUpUser } from "./sagas/saga";
import { Link, useHistory, useParams } from "react-router-dom";
import { userCountry, userLanguage } from "../../utilities/random";
import Home from "@mui/icons-material/Home";
import sha256 from 'crypto-js/sha256';
import { Helmet } from "react-helmet";

export const SignupPage = injectIntl(({ handleSubmit, intl, isLoading }) => {
  const { formatMessage } = intl;
  const history = useHistory()
  const { country = '', language = '' } = useParams();
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`
  const onSignup = (val) => {
    signUpUser({
      name: val.fullName,
      email: val.email,
      phone: val.contactNumber,
      pass: sha256(val.password + process.env.REACT_APP_HASH_KEY).toString(),
    });
  };
  const navigateTo = (path, params =  {}) => {
    history.push(`/${userCountry}/${userLanguage}/${path}`, params)
  }

  return (
    <div style={{ height: window.innerHeight }} className="box bg-white">
      <Helmet>
        <title>Signup | dresez.com</title>
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <center>
            <Typography paragraph style={{ fontWeight: "600" }} variant="h5">
              Sign up
            </Typography>
          </center>

          <form onSubmit={handleSubmit(onSignup)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="fullName"
                  component={GroupField}
                  placeholder={"John Doe"}
                  label={`${formatMessage(commonTranslations.fullName.props)}`}
                  type="text"
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="email"
                  component={GroupField}
                  placeholder={"Johndoe@gmail.com"}
                  label={`${formatMessage(commonTranslations.email.props)}`}
                  type="text"
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="contactNumber"
                  component={GroupField}
                  placeholder={"+92331223222"}
                  label={`${formatMessage(commonTranslations.contact.props)} (optional)`}
                  type="text"
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="password"
                  component={GroupField}
                  label={`${formatMessage(commonTranslations.password.props)}`}
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                  type={"password"}
                />
              </Grid>
              <Grid item xs={12}>
                <center>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {commonTranslations.signup}
                    </Button>
                  )}
                </center>
              </Grid>
              
              <div className="w-full my-2">
                <center>
                  <Link to={navTo('signin')} className="text-[14px] font-bold cursor-pointer text-[#0645AD] underline">{commonTranslations.alreadyHaveAccount} Login</Link>
                </center>
              </div>
            </Grid>
          </form>
          <div className="mt-4" />
          <center><Button onClick={() => navigateTo('')} variant="contained" endIcon={<Home fontSize='small' />}>           
               Go to home
               </Button></center>
        </Box>
      </Container>
    </div>
  );
});
