import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import PostCard from '../../components/PostCard/PostCard'
import { AppLayout } from '../../Layout/AppLayout'
import { fetchUserPosts } from './sagas/saga'

export const OthersPostsPage = () => {
  const { userId = '', country = '', language = '' } = useParams() 
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`
  const history = useHistory()

  const fetchPosts = () => {
    const data = []
    fetchUserPosts({ userId }).then(res => {
      res?.forEach(item => {
        data.push({ ...item.data(), id: item.id })
      })
      setIsLoading(false)
    }).then(() => {
      setList(data)
    }).catch(() => {
      setIsLoading(false)
    })
  }
  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <AppLayout back>
       <Helmet>
        <title>posts | dresez.com</title>
      </Helmet>
      <div className='w-full flex items-center flex-col'>
        <div className='w-full max-w-[1400px] p-2'>
          <div className='w-full mt-4'>
            {
              isLoading ?
                <div className='w-full flex flex-col items-center mt-4'>
                  <CircularProgress color='primary' />
                </div>
                : list.map((item, i) => <PostCard onClick={() => history.push(navTo(`post/${item?.id}`))} isOthers description={item?.description} fetchPosts={fetchPosts} id={item.id} image={item?.images?.[0]} key={i} title={item?.title} condition={item?.condition} />)
            }
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
