import * as fireAuth from "firebase/auth";
import * as fireStore from "firebase/firestore";
import { useEffect, useState } from "react";
import { showNotification } from "../../../components/snackbar/saga";
import store from "../../../store";
import { commonTranslations } from "../../../translation/commonTranslations";
import { db } from "../../../utilities/firebase";
import { GET_USER_LOADING, GET_USER_SUCCESS, GET_USER_FAILED, LOGOUT_USER } from "./types";

export const signUpUser = ({ email, pass, name = '', phone = '' }) => {
  store.dispatch({ type: GET_USER_LOADING });
  var credential = fireAuth.EmailAuthProvider.credential(email, pass);

  fireAuth.linkWithCredential(fireAuth.getAuth().currentUser, credential)
    .then((res) => {
      fireAuth.sendEmailVerification(fireAuth.getAuth().currentUser ,{
      url: process.env.REACT_APP_WEB_BASE_URL
      })
      window.localStorage.setItem("userId", res.user.uid);
      const userData = { email, pass, name, phone, id: res.user.uid };
      fireStore
        .setDoc(fireStore.doc(db, "users", res.user.uid), userData)
        .then((data) => {
          getUser({ userId: res.user.uid });
          showNotification({ message: "Success!" });
        })
        .catch((e) => {
          store.dispatch({ type: GET_USER_FAILED });
          showNotification({ isError: true, message: e.message });
        });
    }).catch((e) => {
      store.dispatch({ type: GET_USER_FAILED });
      showNotification({ isError: true, message: e.message });
    })
};

export const updateUserProfile = ({ userId, userData, notification = true, noUpdate = false }) => new Promise((resolve,reject) => {
  fireStore
        .updateDoc(fireStore.doc(db, "users", userId), userData)
        .then((data) => {
          if (!noUpdate) {
            getUser({ userId: userId });
          }
          if (notification) {
            showNotification({ message: "Success!" });
          }
          resolve()
        })
        .catch((e) => {
          if (!noUpdate) {
          store.dispatch({ type: GET_USER_FAILED });
          }
          if (notification) {
          showNotification({ isError: true, message: e.message });
          }
          reject()
        });
})

export const fetchUser = async () => await fireAuth.getAuth().currentUser;

export const logoutUser = (noReload = false) => {
  fireAuth.signOut(fireAuth.getAuth()).then(() => {
    store.dispatch({ type: LOGOUT_USER })
    localStorage.removeItem('userId')
    if (noReload) return
    window.location.reload()
  }).catch((e) => {
    store.dispatch({ type: GET_USER_FAILED });
    showNotification({ isError: true, message: e.message });
  });
};

export const getUser = ({ userId, errCallback = () => null }) => {
  if (userId) {
    store.dispatch({ type: GET_USER_LOADING });
    fireStore
      .getDoc(fireStore.doc(db, "users", userId))
      .then((res) => {
        if (res?.data()?.isAnonymousUser) {
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        if (res?.data()?.isBlocked) {
          logoutUser(true)
          showNotification({ isError: true, message: 'The user has been blocked' });
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        if (res?.data()?.isDeactivated) {
          logoutUser(true)
          showNotification({ isError: true, message: 'The user has been deactivated' });
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        store.dispatch({ type: GET_USER_SUCCESS, data: res.data() });
      })
      .catch((e) => {
        store.dispatch({ type: GET_USER_FAILED });
        showNotification({ isError: true, message: e.message });
      });
    return;
  }
  store.dispatch({ type: GET_USER_LOADING });
  fireAuth.onAuthStateChanged(fireAuth.getAuth(), (user) => {
    if (user) {
    fireStore
      .getDoc(fireStore.doc(db, "users", user?.uid))
      .then((res) => {
        if (res?.data()?.isAnonymousUser) {
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        if (res?.data()?.isBlocked) {
          logoutUser(true)
          showNotification({ isError: true, message: 'The user has been blocked' });
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        if (res?.data()?.isDeactivated) {
          logoutUser(true)
          showNotification({ isError: true, message: 'The user has been deactivated' });
          store.dispatch({ type: GET_USER_FAILED });
          return
        }
        store.dispatch({ type: GET_USER_SUCCESS, data: res.data() });
      })
      .catch(
        (e) => {
          store.dispatch({ type: GET_USER_FAILED });
          if (user?.uid) return
          errCallback()
        }
      );
    }
    else {
      errCallback()
    }
  });
};

export const onUserVote = ({ userData, country }) => {
  fireStore
  .setDoc(fireStore.doc(db, "vote", userData.uid), {
    uid: userData.uid,
    country
  }).then(() => {
    showNotification({ isError: false, message: commonTranslations.votePlaced});
  })
}

export const useFirebaseUser = () => {
  const [user, setUser] = useState()

  useEffect(() => {
    fireAuth.onAuthStateChanged(fireAuth.getAuth(), (user) => {
      if (user) {
        setUser(user)
      }
    })
  }, [])

  return { user }
}