export const languages = [
    {
        "name": "Afghanistan",
        "alpha2Code": "AF",
        "languages": [
            {
                "iso639_1": "ps",
                "iso639_2": "pus",
                "name": "Pashto",
                "nativeName": "پښتو"
            },
            {
                "iso639_1": "uz",
                "iso639_2": "uzb",
                "name": "Uzbek",
                "nativeName": "Oʻzbek"
            },
            {
                "iso639_1": "tk",
                "iso639_2": "tuk",
                "name": "Turkmen",
                "nativeName": "Türkmen"
            }
        ],
        "independent": false
    },
    {
        "name": "Åland Islands",
        "alpha2Code": "AX",
        "languages": [
            {
                "iso639_1": "sv",
                "iso639_2": "swe",
                "name": "Swedish",
                "nativeName": "svenska"
            }
        ],
        "independent": false
    },
    {
        "name": "Albania",
        "alpha2Code": "AL",
        "languages": [
            {
                "iso639_1": "sq",
                "iso639_2": "sqi",
                "name": "Albanian",
                "nativeName": "Shqip"
            }
        ],
        "independent": false
    },
    {
        "name": "Algeria",
        "alpha2Code": "DZ",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "American Samoa",
        "alpha2Code": "AS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "sm",
                "iso639_2": "smo",
                "name": "Samoan",
                "nativeName": "gagana fa'a Samoa"
            }
        ],
        "independent": false
    },
    {
        "name": "Andorra",
        "alpha2Code": "AD",
        "languages": [
            {
                "iso639_1": "ca",
                "iso639_2": "cat",
                "name": "Catalan",
                "nativeName": "català"
            }
        ],
        "independent": false
    },
    {
        "name": "Angola",
        "alpha2Code": "AO",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Anguilla",
        "alpha2Code": "AI",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Antarctica",
        "alpha2Code": "AQ",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Antigua and Barbuda",
        "alpha2Code": "AG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Argentina",
        "alpha2Code": "AR",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            },
            {
                "iso639_1": "gn",
                "iso639_2": "grn",
                "name": "Guaraní",
                "nativeName": "Avañe'ẽ"
            }
        ],
        "independent": false
    },
    {
        "name": "Armenia",
        "alpha2Code": "AM",
        "languages": [
            {
                "iso639_1": "hy",
                "iso639_2": "hye",
                "name": "Armenian",
                "nativeName": "Հայերեն"
            }
        ],
        "independent": false
    },
    {
        "name": "Aruba",
        "alpha2Code": "AW",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            },
            {
                "iso639_1": "pa",
                "iso639_2": "pan",
                "name": "(Eastern) Punjabi",
                "nativeName": "ਪੰਜਾਬੀ"
            }
        ],
        "independent": false
    },
    {
        "name": "Australia",
        "alpha2Code": "AU",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Austria",
        "alpha2Code": "AT",
        "languages": [
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            }
        ],
        "independent": false
    },
    {
        "name": "Azerbaijan",
        "alpha2Code": "AZ",
        "languages": [
            {
                "iso639_1": "az",
                "iso639_2": "aze",
                "name": "Azerbaijani",
                "nativeName": "azərbaycan dili"
            }
        ],
        "independent": false
    },
    {
        "name": "Bahamas",
        "alpha2Code": "BS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Bahrain",
        "alpha2Code": "BH",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Bangladesh",
        "alpha2Code": "BD",
        "languages": [
            {
                "iso639_1": "bn",
                "iso639_2": "ben",
                "name": "Bengali",
                "nativeName": "বাংলা"
            }
        ],
        "independent": false
    },
    {
        "name": "Barbados",
        "alpha2Code": "BB",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Belarus",
        "alpha2Code": "BY",
        "languages": [
            {
                "iso639_1": "be",
                "iso639_2": "bel",
                "name": "Belarusian",
                "nativeName": "беларуская мова"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Belgium",
        "alpha2Code": "BE",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            }
        ],
        "independent": false
    },
    {
        "name": "Belize",
        "alpha2Code": "BZ",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Benin",
        "alpha2Code": "BJ",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Bermuda",
        "alpha2Code": "BM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Bhutan",
        "alpha2Code": "BT",
        "languages": [
            {
                "iso639_1": "dz",
                "iso639_2": "dzo",
                "name": "Dzongkha",
                "nativeName": "རྫོང་ཁ"
            }
        ],
        "independent": false
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "alpha2Code": "BO",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            },
            {
                "iso639_1": "ay",
                "iso639_2": "aym",
                "name": "Aymara",
                "nativeName": "aymar aru"
            },
            {
                "iso639_1": "qu",
                "iso639_2": "que",
                "name": "Quechua",
                "nativeName": "Runa Simi"
            }
        ],
        "independent": false
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "alpha2Code": "BQ",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            }
        ],
        "independent": false
    },
    {
        "name": "Bosnia and Herzegovina",
        "alpha2Code": "BA",
        "languages": [
            {
                "iso639_1": "bs",
                "iso639_2": "bos",
                "name": "Bosnian",
                "nativeName": "bosanski jezik"
            },
            {
                "iso639_1": "hr",
                "iso639_2": "hrv",
                "name": "Croatian",
                "nativeName": "hrvatski jezik"
            },
            {
                "iso639_1": "sr",
                "iso639_2": "srp",
                "name": "Serbian",
                "nativeName": "српски језик"
            }
        ],
        "independent": false
    },
    {
        "name": "Botswana",
        "alpha2Code": "BW",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "tn",
                "iso639_2": "tsn",
                "name": "Tswana",
                "nativeName": "Setswana"
            }
        ],
        "independent": false
    },
    {
        "name": "Bouvet Island",
        "alpha2Code": "BV",
        "languages": [
            {
                "iso639_1": "no",
                "iso639_2": "nor",
                "name": "Norwegian",
                "nativeName": "Norsk"
            },
            {
                "iso639_1": "nb",
                "iso639_2": "nob",
                "name": "Norwegian Bokmål",
                "nativeName": "Norsk bokmål"
            },
            {
                "iso639_1": "nn",
                "iso639_2": "nno",
                "name": "Norwegian Nynorsk",
                "nativeName": "Norsk nynorsk"
            }
        ],
        "independent": false
    },
    {
        "name": "Brazil",
        "alpha2Code": "BR",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "British Indian Ocean Territory",
        "alpha2Code": "IO",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "United States Minor Outlying Islands",
        "alpha2Code": "UM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Virgin Islands (British)",
        "alpha2Code": "VG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Virgin Islands (U.S.)",
        "alpha2Code": "VI",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Brunei Darussalam",
        "alpha2Code": "BN",
        "languages": [
            {
                "iso639_1": "ms",
                "iso639_2": "msa",
                "name": "Malay",
                "nativeName": "bahasa Melayu"
            }
        ],
        "independent": false
    },
    {
        "name": "Bulgaria",
        "alpha2Code": "BG",
        "languages": [
            {
                "iso639_1": "bg",
                "iso639_2": "bul",
                "name": "Bulgarian",
                "nativeName": "български език"
            }
        ],
        "independent": false
    },
    {
        "name": "Burkina Faso",
        "alpha2Code": "BF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ff",
                "iso639_2": "ful",
                "name": "Fula",
                "nativeName": "Fulfulde"
            }
        ],
        "independent": false
    },
    {
        "name": "Burundi",
        "alpha2Code": "BI",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "rn",
                "iso639_2": "run",
                "name": "Kirundi",
                "nativeName": "Ikirundi"
            }
        ],
        "independent": false
    },
    {
        "name": "Cambodia",
        "alpha2Code": "KH",
        "languages": [
            {
                "iso639_1": "km",
                "iso639_2": "khm",
                "name": "Khmer",
                "nativeName": "ខ្មែរ"
            }
        ],
        "independent": false
    },
    {
        "name": "Cameroon",
        "alpha2Code": "CM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Canada",
        "alpha2Code": "CA",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Cabo Verde",
        "alpha2Code": "CV",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Cayman Islands",
        "alpha2Code": "KY",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Central African Republic",
        "alpha2Code": "CF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "sg",
                "iso639_2": "sag",
                "name": "Sango",
                "nativeName": "yângâ tî sängö"
            }
        ],
        "independent": false
    },
    {
        "name": "Chad",
        "alpha2Code": "TD",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Chile",
        "alpha2Code": "CL",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "China",
        "alpha2Code": "CN",
        "languages": [
            {
                "iso639_1": "zh",
                "iso639_2": "zho",
                "name": "Chinese",
                "nativeName": "中文 (Zhōngwén)"
            }
        ],
        "independent": false
    },
    {
        "name": "Christmas Island",
        "alpha2Code": "CX",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Cocos (Keeling) Islands",
        "alpha2Code": "CC",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Colombia",
        "alpha2Code": "CO",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Comoros",
        "alpha2Code": "KM",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Congo",
        "alpha2Code": "CG",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ln",
                "iso639_2": "lin",
                "name": "Lingala",
                "nativeName": "Lingála"
            }
        ],
        "independent": false
    },
    {
        "name": "Congo (Democratic Republic of the)",
        "alpha2Code": "CD",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ln",
                "iso639_2": "lin",
                "name": "Lingala",
                "nativeName": "Lingála"
            },
            {
                "iso639_1": "kg",
                "iso639_2": "kon",
                "name": "Kongo",
                "nativeName": "Kikongo"
            },
            {
                "iso639_1": "sw",
                "iso639_2": "swa",
                "name": "Swahili",
                "nativeName": "Kiswahili"
            },
            {
                "iso639_1": "lu",
                "iso639_2": "lub",
                "name": "Luba-Katanga",
                "nativeName": "Tshiluba"
            }
        ],
        "independent": false
    },
    {
        "name": "Cook Islands",
        "alpha2Code": "CK",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_2": "rar",
                "name": "Cook Islands Māori",
                "nativeName": "Māori"
            }
        ],
        "independent": false
    },
    {
        "name": "Costa Rica",
        "alpha2Code": "CR",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Croatia",
        "alpha2Code": "HR",
        "languages": [
            {
                "iso639_1": "hr",
                "iso639_2": "hrv",
                "name": "Croatian",
                "nativeName": "hrvatski jezik"
            }
        ],
        "independent": false
    },
    {
        "name": "Cuba",
        "alpha2Code": "CU",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Curaçao",
        "alpha2Code": "CW",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            },
            {
                "iso639_1": "pa",
                "iso639_2": "pan",
                "name": "(Eastern) Punjabi",
                "nativeName": "ਪੰਜਾਬੀ"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Cyprus",
        "alpha2Code": "CY",
        "languages": [
            {
                "iso639_1": "el",
                "iso639_2": "ell",
                "name": "Greek (modern)",
                "nativeName": "ελληνικά"
            },
            {
                "iso639_1": "tr",
                "iso639_2": "tur",
                "name": "Turkish",
                "nativeName": "Türkçe"
            },
            {
                "iso639_1": "hy",
                "iso639_2": "hye",
                "name": "Armenian",
                "nativeName": "Հայերեն"
            }
        ],
        "independent": false
    },
    {
        "name": "Czech Republic",
        "alpha2Code": "CZ",
        "languages": [
            {
                "iso639_1": "cs",
                "iso639_2": "ces",
                "name": "Czech",
                "nativeName": "čeština"
            },
            {
                "iso639_1": "sk",
                "iso639_2": "slk",
                "name": "Slovak",
                "nativeName": "slovenčina"
            }
        ],
        "independent": false
    },
    {
        "name": "Denmark",
        "alpha2Code": "DK",
        "languages": [
            {
                "iso639_1": "da",
                "iso639_2": "dan",
                "name": "Danish",
                "nativeName": "dansk"
            }
        ],
        "independent": false
    },
    {
        "name": "Djibouti",
        "alpha2Code": "DJ",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Dominica",
        "alpha2Code": "DM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Dominican Republic",
        "alpha2Code": "DO",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Ecuador",
        "alpha2Code": "EC",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Egypt",
        "alpha2Code": "EG",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "El Salvador",
        "alpha2Code": "SV",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Equatorial Guinea",
        "alpha2Code": "GQ",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            },
            {
                "iso639_2": "fan",
                "name": "Fang",
                "nativeName": "Fang"
            }
        ],
        "independent": false
    },
    {
        "name": "Eritrea",
        "alpha2Code": "ER",
        "languages": [
            {
                "iso639_1": "ti",
                "iso639_2": "tir",
                "name": "Tigrinya",
                "nativeName": "ትግርኛ"
            },
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_2": "tig",
                "name": "Tigre",
                "nativeName": "ትግረ"
            },
            {
                "iso639_2": "kun",
                "name": "Kunama",
                "nativeName": "Kunama"
            },
            {
                "iso639_2": "ssy",
                "name": "Saho",
                "nativeName": "Saho"
            },
            {
                "iso639_2": "byn",
                "name": "Bilen",
                "nativeName": "ብሊና"
            },
            {
                "iso639_2": "nrb",
                "name": "Nara",
                "nativeName": "Nara"
            },
            {
                "iso639_1": "aa",
                "iso639_2": "aar",
                "name": "Afar",
                "nativeName": "Afar"
            }
        ],
        "independent": false
    },
    {
        "name": "Estonia",
        "alpha2Code": "EE",
        "languages": [
            {
                "iso639_1": "et",
                "iso639_2": "est",
                "name": "Estonian",
                "nativeName": "eesti"
            }
        ],
        "independent": false
    },
    {
        "name": "Ethiopia",
        "alpha2Code": "ET",
        "languages": [
            {
                "iso639_1": "am",
                "iso639_2": "amh",
                "name": "Amharic",
                "nativeName": "አማርኛ"
            }
        ],
        "independent": false
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "alpha2Code": "FK",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Faroe Islands",
        "alpha2Code": "FO",
        "languages": [
            {
                "iso639_1": "fo",
                "iso639_2": "fao",
                "name": "Faroese",
                "nativeName": "føroyskt"
            }
        ],
        "independent": false
    },
    {
        "name": "Fiji",
        "alpha2Code": "FJ",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fj",
                "iso639_2": "fij",
                "name": "Fijian",
                "nativeName": "vosa Vakaviti"
            },
            {
                "iso639_2": "hif",
                "name": "Fiji Hindi",
                "nativeName": "फ़िजी बात"
            },
            {
                "iso639_2": "rtm",
                "name": "Rotuman",
                "nativeName": "Fäeag Rotuma"
            }
        ],
        "independent": false
    },
    {
        "name": "Finland",
        "alpha2Code": "FI",
        "languages": [
            {
                "iso639_1": "fi",
                "iso639_2": "fin",
                "name": "Finnish",
                "nativeName": "suomi"
            },
            {
                "iso639_1": "sv",
                "iso639_2": "swe",
                "name": "Swedish",
                "nativeName": "svenska"
            }
        ],
        "independent": false
    },
    {
        "name": "France",
        "alpha2Code": "FR",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "French Guiana",
        "alpha2Code": "GF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "French Polynesia",
        "alpha2Code": "PF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "French Southern Territories",
        "alpha2Code": "TF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Gabon",
        "alpha2Code": "GA",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Gambia",
        "alpha2Code": "GM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Georgia",
        "alpha2Code": "GE",
        "languages": [
            {
                "iso639_1": "ka",
                "iso639_2": "kat",
                "name": "Georgian",
                "nativeName": "ქართული"
            }
        ],
        "independent": false
    },
    {
        "name": "Germany",
        "alpha2Code": "DE",
        "languages": [
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            }
        ],
        "independent": false
    },
    {
        "name": "Ghana",
        "alpha2Code": "GH",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Gibraltar",
        "alpha2Code": "GI",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Greece",
        "alpha2Code": "GR",
        "languages": [
            {
                "iso639_1": "el",
                "iso639_2": "ell",
                "name": "Greek (modern)",
                "nativeName": "ελληνικά"
            }
        ],
        "independent": false
    },
    {
        "name": "Greenland",
        "alpha2Code": "GL",
        "languages": [
            {
                "iso639_1": "kl",
                "iso639_2": "kal",
                "name": "Kalaallisut",
                "nativeName": "kalaallisut"
            }
        ],
        "independent": false
    },
    {
        "name": "Grenada",
        "alpha2Code": "GD",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Guadeloupe",
        "alpha2Code": "GP",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Guam",
        "alpha2Code": "GU",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ch",
                "iso639_2": "cha",
                "name": "Chamorro",
                "nativeName": "Chamoru"
            },
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Guatemala",
        "alpha2Code": "GT",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Guernsey",
        "alpha2Code": "GG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Guinea",
        "alpha2Code": "GN",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ff",
                "iso639_2": "ful",
                "name": "Fula",
                "nativeName": "Fulfulde"
            }
        ],
        "independent": false
    },
    {
        "name": "Guinea-Bissau",
        "alpha2Code": "GW",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Guyana",
        "alpha2Code": "GY",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Haiti",
        "alpha2Code": "HT",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "ht",
                "iso639_2": "hat",
                "name": "Haitian",
                "nativeName": "Kreyòl ayisyen"
            }
        ],
        "independent": false
    },
    {
        "name": "Heard Island and McDonald Islands",
        "alpha2Code": "HM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Vatican City",
        "alpha2Code": "VA",
        "languages": [
            {
                "iso639_1": "la",
                "iso639_2": "lat",
                "name": "Latin",
                "nativeName": "latine"
            },
            {
                "iso639_1": "it",
                "iso639_2": "ita",
                "name": "Italian",
                "nativeName": "Italiano"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "Français"
            },
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            }
        ],
        "independent": false
    },
    {
        "name": "Honduras",
        "alpha2Code": "HN",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Hungary",
        "alpha2Code": "HU",
        "languages": [
            {
                "iso639_1": "hu",
                "iso639_2": "hun",
                "name": "Hungarian",
                "nativeName": "magyar"
            }
        ],
        "independent": false
    },
    {
        "name": "Hong Kong",
        "alpha2Code": "HK",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "zh",
                "iso639_2": "zho",
                "name": "Chinese",
                "nativeName": "中文 (Zhōngwén)"
            }
        ],
        "independent": false
    },
    {
        "name": "Iceland",
        "alpha2Code": "IS",
        "languages": [
            {
                "iso639_1": "is",
                "iso639_2": "isl",
                "name": "Icelandic",
                "nativeName": "Íslenska"
            }
        ],
        "independent": false
    },
    {
        "name": "India",
        "alpha2Code": "IN",
        "languages": [
            {
                "iso639_1": "hi",
                "iso639_2": "hin",
                "name": "Hindi",
                "nativeName": "हिन्दी"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Indonesia",
        "alpha2Code": "ID",
        "languages": [
            {
                "iso639_1": "id",
                "iso639_2": "ind",
                "name": "Indonesian",
                "nativeName": "Bahasa Indonesia"
            }
        ],
        "independent": false
    },
    {
        "name": "Ivory Coast",
        "alpha2Code": "CI",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Iran (Islamic Republic of)",
        "alpha2Code": "IR",
        "languages": [
            {
                "iso639_1": "fa",
                "iso639_2": "fas",
                "name": "Persian (Farsi)",
                "nativeName": "فارسی"
            }
        ],
        "independent": false
    },
    {
        "name": "Iraq",
        "alpha2Code": "IQ",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            },
            {
                "iso639_1": "ku",
                "iso639_2": "kur",
                "name": "Kurdish",
                "nativeName": "Kurdî"
            }
        ],
        "independent": false
    },
    {
        "name": "Ireland",
        "alpha2Code": "IE",
        "languages": [
            {
                "iso639_1": "ga",
                "iso639_2": "gle",
                "name": "Irish",
                "nativeName": "Gaeilge"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Isle of Man",
        "alpha2Code": "IM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "gv",
                "iso639_2": "glv",
                "name": "Manx",
                "nativeName": "Gaelg"
            }
        ],
        "independent": false
    },
    {
        "name": "Israel",
        "alpha2Code": "IL",
        "languages": [
            {
                "iso639_1": "he",
                "iso639_2": "heb",
                "name": "Hebrew (modern)",
                "nativeName": "עברית"
            },
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Italy",
        "alpha2Code": "IT",
        "languages": [
            {
                "iso639_1": "it",
                "iso639_2": "ita",
                "name": "Italian",
                "nativeName": "Italiano"
            }
        ],
        "independent": false
    },
    {
        "name": "Jamaica",
        "alpha2Code": "JM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Japan",
        "alpha2Code": "JP",
        "languages": [
            {
                "iso639_1": "ja",
                "iso639_2": "jpn",
                "name": "Japanese",
                "nativeName": "日本語 (にほんご)"
            }
        ],
        "independent": false
    },
    {
        "name": "Jersey",
        "alpha2Code": "JE",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Jordan",
        "alpha2Code": "JO",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Kazakhstan",
        "alpha2Code": "KZ",
        "languages": [
            {
                "iso639_1": "kk",
                "iso639_2": "kaz",
                "name": "Kazakh",
                "nativeName": "қазақ тілі"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Kenya",
        "alpha2Code": "KE",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "sw",
                "iso639_2": "swa",
                "name": "Swahili",
                "nativeName": "Kiswahili"
            }
        ],
        "independent": false
    },
    {
        "name": "Kiribati",
        "alpha2Code": "KI",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Kuwait",
        "alpha2Code": "KW",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Kyrgyzstan",
        "alpha2Code": "KG",
        "languages": [
            {
                "iso639_1": "ky",
                "iso639_2": "kir",
                "name": "Kyrgyz",
                "nativeName": "Кыргызча"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Lao People's Democratic Republic",
        "alpha2Code": "LA",
        "languages": [
            {
                "iso639_1": "lo",
                "iso639_2": "lao",
                "name": "Lao",
                "nativeName": "ພາສາລາວ"
            }
        ],
        "independent": false
    },
    {
        "name": "Latvia",
        "alpha2Code": "LV",
        "languages": [
            {
                "iso639_1": "lv",
                "iso639_2": "lav",
                "name": "Latvian",
                "nativeName": "latviešu valoda"
            }
        ],
        "independent": false
    },
    {
        "name": "Lebanon",
        "alpha2Code": "LB",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Lesotho",
        "alpha2Code": "LS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "st",
                "iso639_2": "sot",
                "name": "Southern Sotho",
                "nativeName": "Sesotho"
            }
        ],
        "independent": false
    },
    {
        "name": "Liberia",
        "alpha2Code": "LR",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Libya",
        "alpha2Code": "LY",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Liechtenstein",
        "alpha2Code": "LI",
        "languages": [
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            }
        ],
        "independent": false
    },
    {
        "name": "Lithuania",
        "alpha2Code": "LT",
        "languages": [
            {
                "iso639_1": "lt",
                "iso639_2": "lit",
                "name": "Lithuanian",
                "nativeName": "lietuvių kalba"
            }
        ],
        "independent": false
    },
    {
        "name": "Luxembourg",
        "alpha2Code": "LU",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            },
            {
                "iso639_1": "lb",
                "iso639_2": "ltz",
                "name": "Luxembourgish",
                "nativeName": "Lëtzebuergesch"
            }
        ],
        "independent": false
    },
    {
        "name": "Macao",
        "alpha2Code": "MO",
        "languages": [
            {
                "iso639_1": "zh",
                "iso639_2": "zho",
                "name": "Chinese",
                "nativeName": "中文 (Zhōngwén)"
            },
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "North Macedonia",
        "alpha2Code": "MK",
        "languages": [
            {
                "iso639_1": "mk",
                "iso639_2": "mkd",
                "name": "Macedonian",
                "nativeName": "македонски јазик"
            }
        ],
        "independent": false
    },
    {
        "name": "Madagascar",
        "alpha2Code": "MG",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "mg",
                "iso639_2": "mlg",
                "name": "Malagasy",
                "nativeName": "fiteny malagasy"
            }
        ],
        "independent": false
    },
    {
        "name": "Malawi",
        "alpha2Code": "MW",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ny",
                "iso639_2": "nya",
                "name": "Chichewa",
                "nativeName": "chiCheŵa"
            }
        ],
        "independent": false
    },
    {
        "name": "Malaysia",
        "alpha2Code": "MY",
        "languages": [
            {
                "iso639_1": "ms",
                "iso639_2": "zsm",
                "name": "Malaysian",
                "nativeName": "بهاس مليسيا"
            }
        ],
        "independent": false
    },
    {
        "name": "Maldives",
        "alpha2Code": "MV",
        "languages": [
            {
                "iso639_1": "dv",
                "iso639_2": "div",
                "name": "Divehi",
                "nativeName": "ދިވެހި"
            }
        ],
        "independent": false
    },
    {
        "name": "Mali",
        "alpha2Code": "ML",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Malta",
        "alpha2Code": "MT",
        "languages": [
            {
                "iso639_1": "mt",
                "iso639_2": "mlt",
                "name": "Maltese",
                "nativeName": "Malti"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Marshall Islands",
        "alpha2Code": "MH",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "mh",
                "iso639_2": "mah",
                "name": "Marshallese",
                "nativeName": "Kajin M̧ajeļ"
            }
        ],
        "independent": false
    },
    {
        "name": "Martinique",
        "alpha2Code": "MQ",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Mauritania",
        "alpha2Code": "MR",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Mauritius",
        "alpha2Code": "MU",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Mayotte",
        "alpha2Code": "YT",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Mexico",
        "alpha2Code": "MX",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Micronesia (Federated States of)",
        "alpha2Code": "FM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Moldova (Republic of)",
        "alpha2Code": "MD",
        "languages": [
            {
                "iso639_1": "ro",
                "iso639_2": "ron",
                "name": "Romanian",
                "nativeName": "Română"
            }
        ],
        "independent": false
    },
    {
        "name": "Monaco",
        "alpha2Code": "MC",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Mongolia",
        "alpha2Code": "MN",
        "languages": [
            {
                "iso639_1": "mn",
                "iso639_2": "mon",
                "name": "Mongolian",
                "nativeName": "Монгол хэл"
            }
        ],
        "independent": false
    },
    {
        "name": "Montenegro",
        "alpha2Code": "ME",
        "languages": [
            {
                "iso639_1": "sr",
                "iso639_2": "srp",
                "name": "Serbian",
                "nativeName": "српски језик"
            },
            {
                "iso639_1": "bs",
                "iso639_2": "bos",
                "name": "Bosnian",
                "nativeName": "bosanski jezik"
            },
            {
                "iso639_1": "sq",
                "iso639_2": "sqi",
                "name": "Albanian",
                "nativeName": "Shqip"
            },
            {
                "iso639_1": "hr",
                "iso639_2": "hrv",
                "name": "Croatian",
                "nativeName": "hrvatski jezik"
            }
        ],
        "independent": false
    },
    {
        "name": "Montserrat",
        "alpha2Code": "MS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Morocco",
        "alpha2Code": "MA",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Mozambique",
        "alpha2Code": "MZ",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Myanmar",
        "alpha2Code": "MM",
        "languages": [
            {
                "iso639_1": "my",
                "iso639_2": "mya",
                "name": "Burmese",
                "nativeName": "ဗမာစာ"
            }
        ],
        "independent": false
    },
    {
        "name": "Namibia",
        "alpha2Code": "NA",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "af",
                "iso639_2": "afr",
                "name": "Afrikaans",
                "nativeName": "Afrikaans"
            }
        ],
        "independent": false
    },
    {
        "name": "Nauru",
        "alpha2Code": "NR",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "na",
                "iso639_2": "nau",
                "name": "Nauruan",
                "nativeName": "Dorerin Naoero"
            }
        ],
        "independent": false
    },
    {
        "name": "Nepal",
        "alpha2Code": "NP",
        "languages": [
            {
                "iso639_1": "ne",
                "iso639_2": "nep",
                "name": "Nepali",
                "nativeName": "नेपाली"
            }
        ],
        "independent": false
    },
    {
        "name": "Netherlands",
        "alpha2Code": "NL",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            }
        ],
        "independent": false
    },
    {
        "name": "New Caledonia",
        "alpha2Code": "NC",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "New Zealand",
        "alpha2Code": "NZ",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "mi",
                "iso639_2": "mri",
                "name": "Māori",
                "nativeName": "te reo Māori"
            }
        ],
        "independent": false
    },
    {
        "name": "Nicaragua",
        "alpha2Code": "NI",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Niger",
        "alpha2Code": "NE",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Nigeria",
        "alpha2Code": "NG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Niue",
        "alpha2Code": "NU",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Norfolk Island",
        "alpha2Code": "NF",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Korea (Democratic People's Republic of)",
        "alpha2Code": "KP",
        "languages": [
            {
                "iso639_1": "ko",
                "iso639_2": "kor",
                "name": "Korean",
                "nativeName": "한국어"
            }
        ],
        "independent": false
    },
    {
        "name": "Northern Mariana Islands",
        "alpha2Code": "MP",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ch",
                "iso639_2": "cha",
                "name": "Chamorro",
                "nativeName": "Chamoru"
            }
        ],
        "independent": false
    },
    {
        "name": "Norway",
        "alpha2Code": "NO",
        "languages": [
            {
                "iso639_1": "no",
                "iso639_2": "nor",
                "name": "Norwegian",
                "nativeName": "Norsk"
            },
            {
                "iso639_1": "nb",
                "iso639_2": "nob",
                "name": "Norwegian Bokmål",
                "nativeName": "Norsk bokmål"
            },
            {
                "iso639_1": "nn",
                "iso639_2": "nno",
                "name": "Norwegian Nynorsk",
                "nativeName": "Norsk nynorsk"
            }
        ],
        "independent": false
    },
    {
        "name": "Oman",
        "alpha2Code": "OM",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Pakistan",
        "alpha2Code": "PK",
        "languages": [
            {
                "iso639_1": "ur",
                "iso639_2": "urd",
                "name": "Urdu",
                "nativeName": "اردو"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Palau",
        "alpha2Code": "PW",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Palestine, State of",
        "alpha2Code": "PS",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Panama",
        "alpha2Code": "PA",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Papua New Guinea",
        "alpha2Code": "PG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Paraguay",
        "alpha2Code": "PY",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            },
            {
                "iso639_1": "gn",
                "iso639_2": "grn",
                "name": "Guaraní",
                "nativeName": "Avañe'ẽ"
            }
        ],
        "independent": false
    },
    {
        "name": "Peru",
        "alpha2Code": "PE",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Philippines",
        "alpha2Code": "PH",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Pitcairn",
        "alpha2Code": "PN",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Poland",
        "alpha2Code": "PL",
        "languages": [
            {
                "iso639_1": "pl",
                "iso639_2": "pol",
                "name": "Polish",
                "nativeName": "język polski"
            }
        ],
        "independent": false
    },
    {
        "name": "Portugal",
        "alpha2Code": "PT",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Puerto Rico",
        "alpha2Code": "PR",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Qatar",
        "alpha2Code": "QA",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Republic of Kosovo",
        "alpha2Code": "XK",
        "languages": [
            {
                "iso639_1": "sq",
                "iso639_2": "sqi",
                "name": "Albanian",
                "nativeName": "Shqip"
            },
            {
                "iso639_1": "sr",
                "iso639_2": "srp",
                "name": "Serbian",
                "nativeName": "српски језик"
            }
        ],
        "independent": false
    },
    {
        "name": "Réunion",
        "alpha2Code": "RE",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Romania",
        "alpha2Code": "RO",
        "languages": [
            {
                "iso639_1": "ro",
                "iso639_2": "ron",
                "name": "Romanian",
                "nativeName": "Română"
            }
        ],
        "independent": false
    },
    {
        "name": "Russian Federation",
        "alpha2Code": "RU",
        "languages": [
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Rwanda",
        "alpha2Code": "RW",
        "languages": [
            {
                "iso639_1": "rw",
                "iso639_2": "kin",
                "name": "Kinyarwanda",
                "nativeName": "Ikinyarwanda"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Barthélemy",
        "alpha2Code": "BL",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "alpha2Code": "SH",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Kitts and Nevis",
        "alpha2Code": "KN",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Lucia",
        "alpha2Code": "LC",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Martin (French part)",
        "alpha2Code": "MF",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Pierre and Miquelon",
        "alpha2Code": "PM",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "alpha2Code": "VC",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Samoa",
        "alpha2Code": "WS",
        "languages": [
            {
                "iso639_1": "sm",
                "iso639_2": "smo",
                "name": "Samoan",
                "nativeName": "gagana fa'a Samoa"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "San Marino",
        "alpha2Code": "SM",
        "languages": [
            {
                "iso639_1": "it",
                "iso639_2": "ita",
                "name": "Italian",
                "nativeName": "Italiano"
            }
        ],
        "independent": false
    },
    {
        "name": "Sao Tome and Principe",
        "alpha2Code": "ST",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Saudi Arabia",
        "alpha2Code": "SA",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Senegal",
        "alpha2Code": "SN",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Serbia",
        "alpha2Code": "RS",
        "languages": [
            {
                "iso639_1": "sr",
                "iso639_2": "srp",
                "name": "Serbian",
                "nativeName": "српски језик"
            }
        ],
        "independent": false
    },
    {
        "name": "Seychelles",
        "alpha2Code": "SC",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Sierra Leone",
        "alpha2Code": "SL",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Singapore",
        "alpha2Code": "SG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ms",
                "iso639_2": "msa",
                "name": "Malay",
                "nativeName": "bahasa Melayu"
            },
            {
                "iso639_1": "ta",
                "iso639_2": "tam",
                "name": "Tamil",
                "nativeName": "தமிழ்"
            },
            {
                "iso639_1": "zh",
                "iso639_2": "zho",
                "name": "Chinese",
                "nativeName": "中文 (Zhōngwén)"
            }
        ],
        "independent": false
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "alpha2Code": "SX",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Slovakia",
        "alpha2Code": "SK",
        "languages": [
            {
                "iso639_1": "sk",
                "iso639_2": "slk",
                "name": "Slovak",
                "nativeName": "slovenčina"
            }
        ],
        "independent": false
    },
    {
        "name": "Slovenia",
        "alpha2Code": "SI",
        "languages": [
            {
                "iso639_1": "sl",
                "iso639_2": "slv",
                "name": "Slovene",
                "nativeName": "slovenski jezik"
            }
        ],
        "independent": false
    },
    {
        "name": "Solomon Islands",
        "alpha2Code": "SB",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Somalia",
        "alpha2Code": "SO",
        "languages": [
            {
                "iso639_1": "so",
                "iso639_2": "som",
                "name": "Somali",
                "nativeName": "Soomaaliga"
            },
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "South Africa",
        "alpha2Code": "ZA",
        "languages": [
            {
                "iso639_1": "af",
                "iso639_2": "afr",
                "name": "Afrikaans",
                "nativeName": "Afrikaans"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "nr",
                "iso639_2": "nbl",
                "name": "Southern Ndebele",
                "nativeName": "isiNdebele"
            },
            {
                "iso639_1": "st",
                "iso639_2": "sot",
                "name": "Southern Sotho",
                "nativeName": "Sesotho"
            },
            {
                "iso639_1": "ss",
                "iso639_2": "ssw",
                "name": "Swati",
                "nativeName": "SiSwati"
            },
            {
                "iso639_1": "tn",
                "iso639_2": "tsn",
                "name": "Tswana",
                "nativeName": "Setswana"
            },
            {
                "iso639_1": "ts",
                "iso639_2": "tso",
                "name": "Tsonga",
                "nativeName": "Xitsonga"
            },
            {
                "iso639_1": "ve",
                "iso639_2": "ven",
                "name": "Venda",
                "nativeName": "Tshivenḓa"
            },
            {
                "iso639_1": "xh",
                "iso639_2": "xho",
                "name": "Xhosa",
                "nativeName": "isiXhosa"
            },
            {
                "iso639_1": "zu",
                "iso639_2": "zul",
                "name": "Zulu",
                "nativeName": "isiZulu"
            }
        ],
        "independent": false
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "alpha2Code": "GS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Korea (Republic of)",
        "alpha2Code": "KR",
        "languages": [
            {
                "iso639_1": "ko",
                "iso639_2": "kor",
                "name": "Korean",
                "nativeName": "한국어"
            }
        ],
        "independent": false
    },
    {
        "name": "Spain",
        "alpha2Code": "ES",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Sri Lanka",
        "alpha2Code": "LK",
        "languages": [
            {
                "iso639_1": "si",
                "iso639_2": "sin",
                "name": "Sinhalese",
                "nativeName": "සිංහල"
            },
            {
                "iso639_1": "ta",
                "iso639_2": "tam",
                "name": "Tamil",
                "nativeName": "தமிழ்"
            }
        ],
        "independent": false
    },
    {
        "name": "Sudan",
        "alpha2Code": "SD",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "South Sudan",
        "alpha2Code": "SS",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Suriname",
        "alpha2Code": "SR",
        "languages": [
            {
                "iso639_1": "nl",
                "iso639_2": "nld",
                "name": "Dutch",
                "nativeName": "Nederlands"
            }
        ],
        "independent": false
    },
    {
        "name": "Svalbard and Jan Mayen",
        "alpha2Code": "SJ",
        "languages": [
            {
                "iso639_1": "no",
                "iso639_2": "nor",
                "name": "Norwegian",
                "nativeName": "Norsk"
            }
        ],
        "independent": false
    },
    {
        "name": "Swaziland",
        "alpha2Code": "SZ",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "ss",
                "iso639_2": "ssw",
                "name": "Swati",
                "nativeName": "SiSwati"
            }
        ],
        "independent": false
    },
    {
        "name": "Sweden",
        "alpha2Code": "SE",
        "languages": [
            {
                "iso639_1": "sv",
                "iso639_2": "swe",
                "name": "Swedish",
                "nativeName": "svenska"
            }
        ],
        "independent": false
    },
    {
        "name": "Switzerland",
        "alpha2Code": "CH",
        "languages": [
            {
                "iso639_1": "de",
                "iso639_2": "deu",
                "name": "German",
                "nativeName": "Deutsch"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            },
            {
                "iso639_1": "it",
                "iso639_2": "ita",
                "name": "Italian",
                "nativeName": "Italiano"
            },
            {
                "iso639_2": "roh",
                "name": "Romansh"
            }
        ],
        "independent": false
    },
    {
        "name": "Syrian Arab Republic",
        "alpha2Code": "SY",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Taiwan",
        "alpha2Code": "TW",
        "languages": [
            {
                "iso639_1": "zh",
                "iso639_2": "zho",
                "name": "Chinese",
                "nativeName": "中文 (Zhōngwén)"
            }
        ],
        "independent": false
    },
    {
        "name": "Tajikistan",
        "alpha2Code": "TJ",
        "languages": [
            {
                "iso639_1": "tg",
                "iso639_2": "tgk",
                "name": "Tajik",
                "nativeName": "тоҷикӣ"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Tanzania, United Republic of",
        "alpha2Code": "TZ",
        "languages": [
            {
                "iso639_1": "sw",
                "iso639_2": "swa",
                "name": "Swahili",
                "nativeName": "Kiswahili"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Thailand",
        "alpha2Code": "TH",
        "languages": [
            {
                "iso639_1": "th",
                "iso639_2": "tha",
                "name": "Thai",
                "nativeName": "ไทย"
            }
        ],
        "independent": false
    },
    {
        "name": "Timor-Leste",
        "alpha2Code": "TL",
        "languages": [
            {
                "iso639_1": "pt",
                "iso639_2": "por",
                "name": "Portuguese",
                "nativeName": "Português"
            }
        ],
        "independent": false
    },
    {
        "name": "Togo",
        "alpha2Code": "TG",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Tokelau",
        "alpha2Code": "TK",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Tonga",
        "alpha2Code": "TO",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "to",
                "iso639_2": "ton",
                "name": "Tonga (Tonga Islands)",
                "nativeName": "faka Tonga"
            }
        ],
        "independent": false
    },
    {
        "name": "Trinidad and Tobago",
        "alpha2Code": "TT",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Tunisia",
        "alpha2Code": "TN",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Turkey",
        "alpha2Code": "TR",
        "languages": [
            {
                "iso639_1": "tr",
                "iso639_2": "tur",
                "name": "Turkish",
                "nativeName": "Türkçe"
            }
        ],
        "independent": false
    },
    {
        "name": "Turkmenistan",
        "alpha2Code": "TM",
        "languages": [
            {
                "iso639_1": "tk",
                "iso639_2": "tuk",
                "name": "Turkmen",
                "nativeName": "Türkmen"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Turks and Caicos Islands",
        "alpha2Code": "TC",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Tuvalu",
        "alpha2Code": "TV",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Uganda",
        "alpha2Code": "UG",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "sw",
                "iso639_2": "swa",
                "name": "Swahili",
                "nativeName": "Kiswahili"
            }
        ],
        "independent": false
    },
    {
        "name": "Ukraine",
        "alpha2Code": "UA",
        "languages": [
            {
                "iso639_1": "uk",
                "iso639_2": "ukr",
                "name": "Ukrainian",
                "nativeName": "Українська"
            }
        ],
        "independent": false
    },
    {
        "name": "United Arab Emirates",
        "alpha2Code": "AE",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "alpha2Code": "GB",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "United States of America",
        "alpha2Code": "US",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Uruguay",
        "alpha2Code": "UY",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Uzbekistan",
        "alpha2Code": "UZ",
        "languages": [
            {
                "iso639_1": "uz",
                "iso639_2": "uzb",
                "name": "Uzbek",
                "nativeName": "Oʻzbek"
            },
            {
                "iso639_1": "ru",
                "iso639_2": "rus",
                "name": "Russian",
                "nativeName": "Русский"
            }
        ],
        "independent": false
    },
    {
        "name": "Vanuatu",
        "alpha2Code": "VU",
        "languages": [
            {
                "iso639_1": "bi",
                "iso639_2": "bis",
                "name": "Bislama",
                "nativeName": "Bislama"
            },
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "alpha2Code": "VE",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Vietnam",
        "alpha2Code": "VN",
        "languages": [
            {
                "iso639_1": "vi",
                "iso639_2": "vie",
                "name": "Vietnamese",
                "nativeName": "Tiếng Việt"
            }
        ],
        "independent": false
    },
    {
        "name": "Wallis and Futuna",
        "alpha2Code": "WF",
        "languages": [
            {
                "iso639_1": "fr",
                "iso639_2": "fra",
                "name": "French",
                "nativeName": "français"
            }
        ],
        "independent": false
    },
    {
        "name": "Western Sahara",
        "alpha2Code": "EH",
        "languages": [
            {
                "iso639_1": "es",
                "iso639_2": "spa",
                "name": "Spanish",
                "nativeName": "Español"
            }
        ],
        "independent": false
    },
    {
        "name": "Yemen",
        "alpha2Code": "YE",
        "languages": [
            {
                "iso639_1": "ar",
                "iso639_2": "ara",
                "name": "Arabic",
                "nativeName": "العربية"
            }
        ],
        "independent": false
    },
    {
        "name": "Zambia",
        "alpha2Code": "ZM",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            }
        ],
        "independent": false
    },
    {
        "name": "Zimbabwe",
        "alpha2Code": "ZW",
        "languages": [
            {
                "iso639_1": "en",
                "iso639_2": "eng",
                "name": "English",
                "nativeName": "English"
            },
            {
                "iso639_1": "sn",
                "iso639_2": "sna",
                "name": "Shona",
                "nativeName": "chiShona"
            },
            {
                "iso639_1": "nd",
                "iso639_2": "nde",
                "name": "Northern Ndebele",
                "nativeName": "isiNdebele"
            }
        ],
        "independent": false
    }
]