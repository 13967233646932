import { SignupPage } from './SignupPage'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import validate from './validate'

const mapStateToProps = ({ form, user }) => ({
  form: form,
  isLoading: user.isLoading
})

const SignUpWrapper = reduxForm({
    form: 'signupForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    validate
  })(connect(mapStateToProps, {})(SignupPage))
  
  export default SignUpWrapper