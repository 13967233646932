import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import Slide from "../../components/slider/Slide";
import { AppLayout } from "../../Layout/AppLayout";
import { countries } from "../../utilities/countries";
import { numberWithCommas, userPlaceholderImage } from "../../utilities/random";
import { fetchSinglePost } from "../AddPostPage/sagas/saga";
import { createChat, getUserDetails } from "./saga/saga";
import { Phone, Mail, Chat } from "@mui/icons-material";
import ErrorBoundary from "../../components/ErrorPage/errorBoundary";
import './styles.scss'
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import * as fireAuth from "firebase/auth";

export default function PostDetailPage() {
  const [images, setImages] = useState([]);
  const currentUser = useSelector(state => state.user)
  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const history = useHistory()
  const { country, language , ...params} = useParams();
  const navTo = (path, params = {}) => `/${country}/${language}/${path}`;
  const onCall = () => window.open(`tel: ${user?.phone}`);
  const onEmail = () => window.open(`mailto: ${user?.email}`);

  const onChat = () => {
    const sender = currentUser?.user?.id || fireAuth.getAuth().currentUser.uid
    const reciever = user?.id
    if (!sender) {
      history.push(navTo('signin'))
      return
    }

    createChat({ senderId: sender, recieverId: reciever, postId: params?.postId }).then((id) => {
      history.push(navTo(`chats/${id}`))
    })
  }

  useEffect(() => {
    fetchSinglePost({ postId: params?.postId })
      .then((res) => {
        const {
          title = "",
          description = "",
          gender = "",
          condition = "",
          price = "",
          discount = "",
          images = ["http://via.placeholder.com/640x360"],
          id = "",
          currencyCode = "",
          createdAt = Timestamp.now(),
          userId,
        } = res;
        getUserDetails({ userId }).then((user) => {
          setUser(user);
        });
        setData(res);
        setImages(
          images?.length > 0 ? images : ["http://via.placeholder.com/640x360"]
        );
        setIsLoading(false);
      })
      .catch((e) => {
        setIsError(e);
        setIsLoading(false);
      });
  }, []);

  if (isError) {
    return <ErrorBoundary message={isError} />;
  }
  if (isLoading) {
    return <FullPageLoading />;
  }

  return (
    <AppLayout back>
      <Helmet>
        <title>Buy sell {data?.title?.split(' ').slice(0, 6).join(' ')} | dresez.com</title>
      </Helmet>
      <div className="w-full flex items-center flex-col py-8">
        <div className="max-w-[1400px] w-full px-2 flex md:gap-[15px] sm:gap-0 sliderRightContainer">
          <div className="sliderRightBox">
            <div className="w-full bg-black">
              <Slide imageList={images} />
            </div>
            <div className="w-full border rounded p-2 mt-3">
              <p className="text-[1.5rem] font-bold">Details</p>
              <div className="flex items-center gap-[3rem] mt-2">
                <p className="text-[1rem] font-bold">
                  <span className="text-gray-600 font-normal">Condition:</span>{" "}
                  {data?.condition}
                </p>
                <p className="text-[1rem] font-bold">
                  <span className="text-gray-600 font-normal">Discount:</span>{" "}
                  {data?.discount || 0}
                </p>
              </div>
            </div>
            <div className="w-full border rounded p-2 mt-1">
              <p className="text-[1.5rem] font-bold">Description</p>
              <p className="text-[1rem]">{data?.description}</p>
            </div>
          </div>
          <div className="flex flex-1 sm:w-full flex-col">
            <div className="w-full border rounded p-2 sm:mt-2 md:mt-0 py-4">
              <p className="md:text-[40px] sm:text-[20px] font-bold">
                {data?.currencyCode} {numberWithCommas(data?.price || 0)}
              </p>
              <p className="text-[1.1rem] text-[#002f34] capitalize">
                {data?.title} up for sale in{" "}
                {
                  countries.find(
                    (item) => item.code.toLowerCase() === data?.country
                  )?.label
                }
              </p>
              <p className="text-[0.9rem] text-gray-400 capitalize">
                Published on{" "}
                {moment(data.createdAt.toDate()).format("DD/MM/YYYY")}
              </p>
            </div>
            {user?.id && (
              <div className="w-full border rounded p-2 mt-2 flex items-center justify-center flex-col py-4">
                <img
                  src={user?.userImage || userPlaceholderImage}
                  className="w-[80px] h-[80px] rounded-full"
                />
                <p className="mt-2 text-[18px] font-bold">{user?.brandName || user?.name || 'dresez user'}</p>

                <div className="flex items-center justify-center mt-3 gap-2">
                  {user?.phone && (
                    <button
                      onClick={onCall}
                      class="btn btn-primary flex items-center justify-center"
                    >
                      <Phone />
                      <p className="text-[16px] ml-2 font-bold">Call</p>
                    </button>
                  )}
                  {currentUser?.user?.id !== user?.id && 
                  <button
                      onClick={onChat}
                      class="btn btn-primary flex items-center justify-center"
                    >
                      <Chat />
                      <p className="text-[16px] ml-2 font-bold">Chat</p>
                    </button>}
                  {user?.email && (
                    <button
                      onClick={onEmail}
                      class="btn btn-primary flex items-center justify-center"
                    >
                      <Mail />
                      <p className="text-[16px] ml-2 font-bold">Email</p>
                    </button>
                  )}
                </div>

                    <Button  variant="contained" className="mt-3" onClick={() => history.push(navTo(`user/${user?.id}`))}>
                      go to profile
                    </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
