import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import styles from './styles.scss'

const useStyles = makeStyles(() => ({
  message: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
}))

const SnackBarComponent = (props) => {
  const classes = useStyles()
  const { showNotification, notification, closeNotifier, isLogin } = props
  const { message, type } = notification
  const getStyleFromStatus = () => {
    let styleObject = { display: 'none' }
    switch (type) {
      case 'success':
        styleObject = { position: isLogin && 'initial' }
        return styleObject
      case 'fail':
        styleObject = { position: isLogin && 'initial' }
        return styleObject
      default:
        return styleObject
    }
  }
  const style = getStyleFromStatus()
  const ref = useRef(null)

  useEffect(() => {
    if (!showNotification) return
    clearTimeout(ref)
    ref.current = setTimeout(() => {
      closeNotifier()
    }, 6000)
  }, [showNotification, closeNotifier])

  if (showNotification) {
  return (
    <div className='w-full position-fixed bottom-16 z-50 left-2'>
    <div className={`align-items-center ${type === 'success' ? 'text-bg-success' : 'text-bg-danger'} border-0 p-3 w-max`}>
  <div className="d-flex">
    <div className="toast-body">
      {message}
    </div>
    <button onClick={closeNotifier} type="button" className="ml-4 text-[16px]">X</button>
  </div>
</div>
</div>
  )
  }

  return null
  // return (
  //   <Snackbar
  //     style={style}
  //     className={styles.snackbar}
  //     open={showNotification}
  //   >
  //     <Alert
  //       classes={{
  //         root: classes.root,
  //         message: classes.message
  //       }}
  //       icon={false}
  //       className={`${styles.alert} flexRow`}
  //       severity={type === 'fail' ? 'error' : 'success'}
  //       onClose={closeNotifier}
  //       variant='filled'
  //     >
  //       <div className={styles.message}>{message}</div>
  //     </Alert>
  //   </Snackbar>
  // )
}

export default SnackBarComponent
