import intl from '../translation/reducers'
import userReducer from '../containers/SignupPage/sagas/reducer'
import auth from '../containers/CountryPage/sagas/reducer'
import notifierReducer from '../components/snackbar/reducer'
import { reducer as formReducer } from 'redux-form'

const reducers = {
  intl,
  auth,
  form: formReducer,
  notifierReducer,
  user: userReducer
}

export default reducers
