import { useEffect, useState } from "react"
import { fetchRealTimeChatMessages, fetchRealTimeChatMessagesForIndication, fetchRealTimeConversation, fetchRealTimeUserConversationList } from "../containers/ChatPage/sagas/saga"

export const chatIdGenerator = ({ senderId, recieverId }) => {
    const arr = [senderId, recieverId]
    return arr.sort((a,b) => a - b).join('-')
}

export const useFetchLatestMessage = (chatId) => {
    const [lastMessage, setLastMessage] = useState('')

    useEffect(() => {
    if (!chatId) return
    fetchRealTimeChatMessages({ chatId, setMessage: setLastMessage })
    }, [chatId])
    return { lastMessage }
}

export const useFetchLatestConversations = (userId) => {
    const [list, setList] = useState({})
    useEffect(() => {
    if (!userId) return
    fetchRealTimeUserConversationList({ userId, setConversation: setList })
    }, [userId])
    return { list }
}

export const useFetchIndication = (userId) => {
    const [list, setList] = useState({})
    useEffect(() => {
    if (!userId) return
    fetchRealTimeChatMessagesForIndication({ userId, setList })
    }, [userId])
    return { list }
}

export const useFetchUpdatedConversations = ({ userId, conversationList, selectedConversation }) => {
    const [list, setList] = useState([])
    useEffect(() => {
    if (conversationList?.length === 0) return
    fetchRealTimeConversation({ userId: userId, conversations: conversationList, setConversations: setList, selectedConversationId: selectedConversation?.id })
    }, [userId, selectedConversation, conversationList?.length])
    
    return { list }
}

export const chatConstants = {
    delivered: 'delivered',
    seen: 'seen'
}