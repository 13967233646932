import React from 'react'
import error from '../../assets/images/error.png'
import { commonTranslations } from '../../translation/commonTranslations'

function ErrorBoundary({ message }) {
  return (
    <div  style={{ height: window.innerHeight }} className="w-full items-center justify-center flex flex-col">
      <img src={error} className={'max-w-[300px] w-full'} />
      <p className='mt-4 text-xl'>{message || 'Some error occoured please try again later'}</p>
      </div>
  )
}

export default ErrorBoundary