import { commonTranslations } from '../../translation/commonTranslations'
import isEmail from 'validator/lib/isEmail'

const validate = (values) => {
  const {
    fullName,
    email,
    password
  } = values
  const errors = {}
  if (!fullName || fullName.trim() === '') {
    errors.fullName = commonTranslations.required
  }
  if (!password || password.trim() === '') {
    errors.password = commonTranslations.required
  }
  if (email && !isEmail(email)) {
    errors.email = commonTranslations.enterValidEmail
  }
  return { ...errors }
}

export default validate
