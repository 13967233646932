import { Autocomplete, Box, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/ArrowRightAlt";
import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading/Heading";
import { countries } from "../../utilities/countries";
import { useHistory, useParams } from "react-router-dom";
import { languages } from "../../utilities/languages";
import { setLanguage } from "../CountryPage/sagas/saga";
import { useSelector } from "react-redux";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import ErrorBoundary from "../../components/ErrorPage/errorBoundary";

function Languagepage() {
  const { country } = useParams();
  const history = useHistory();
  const [selectedLanguage, setselectedLanguage] = useState("en");
  const { isLoading, isError, error, user } = useSelector(
    (state) => state.auth
  );
  const languageList = languages.filter(
    (item) => item.alpha2Code.toLowerCase() === country
  )?.[0]?.languages;
  const langCodes = [
    // ...languageList,
    {
      iso639_1: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
  ];
  const filteredLangData = langCodes.filter((value, index, self) => {
    const _value = JSON.stringify(value)
    return index === langCodes.findIndex(obj => {
      return JSON.stringify(obj) === _value
    })
  })
  const goToHome = () => { 
    history.push(`/${country}/${selectedLanguage}`);
    setLanguage(selectedLanguage)
    window.localStorage.setItem('language', selectedLanguage)
  }

  useEffect(() => {
    if (!countries.find(item => item.code.toLowerCase() === country)?.code) {
      history.push('/')
      return
    }
    goToHome()
    // const language = window.localStorage.getItem('language')

    // if (language) {
    //   history.push(`/${country}/${language}`)
    //   return;
    // }
  }, [])
  

  if (isLoading) {
    return <FullPageLoading />;
  }

  if (isError) {
    return <ErrorBoundary message={error} />;
  }

  return (
    <div
      style={{ height: window.innerHeight }}
      className="w-full items-center justify-center flex flex-col"
    >
      <div className="w-[90%] md:w-[80%]">
        <Heading>Choose a language</Heading>
        <br />
        <Autocomplete
          className="w-full"
          id="country-select-demo"
          options={filteredLangData.map((item) => ({
            ...item,
            code: country.toLowerCase(),
          }))}
          autoHighlight
          onChange={(e, val) => setselectedLanguage(val.iso639_1.toLowerCase())}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={"select your language here"}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
        />
        <div className="w-full flex flex-col items-center justify-center mt-10">
          <Button disabled={!selectedLanguage} onClick={goToHome} variant="contained" endIcon={<SendIcon />}>
            <p className="font-bold">Continue</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Languagepage;
