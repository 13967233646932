
import { ImageCrush } from '@assetcrush/reactjs-sdk'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Slider from 'react-slick'
import './Slide.scss'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, backgroundColor: 'black', borderRadius: 999 }}
      onClick={onClick}
    />
  );
}

export default function Slide ({ imageList, className }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
  }
  return (
    <div className='slide'>
      <Slider {...settings}>
        {
            imageList?.map((img, i) => (
              <div key={i} className='single-slide'>
                <div className='img-wrapper'>
                  <img src={img} alt='images' />
                  {/* <div className='overlay-wrapper'>
                    <p className='text-white text-[12px] mr-2 mb-2'>Powered by Assetcursh</p>
                  </div> */}
                </div>
              </div>

            ))
        }

      </Slider>
    </div>
  )
}
