import { commonTranslations } from '../../translation/commonTranslations'
import validator from 'validator'

const validate = (values) => {
  const {
    email,
    password,
    verificationPhone
  } = values
  const errors = {}
  if (!password || password.trim() === '') {
    errors.password = commonTranslations.required
  }
  if (email && !validator.isEmail(email)) {
    errors.email = commonTranslations.enterValidEmail
  }
  if (verificationPhone && !validator.isMobilePhone(verificationPhone)) {
    errors.verificationPhone = commonTranslations.invalidPhone
  }
  return { ...errors }
}

export default validate
