import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import PostHomeCard from '../../components/PostHomeCard/PostHomeCard'
import { AppLayout } from '../../Layout/AppLayout'
import { fetchLatestPostsWithPagination } from '../HomePage/saga/saga'

export default function ViewAllPosts() {
  const { country = '' } = useParams();
  const history = useHistory()
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])
    const [search, setSearch] = useState('')
    const [latestPostLoading, setLatestPostLoading] = useState(false)
    const navigateTo = (path, params =  {}) => {
      const country = window.localStorage.getItem('country')
      const language = window.localStorage.getItem('language')
      history.push(`/${country}/${language}/${path}`, params)
    }

    useEffect(() => {
        _fetchLatestPosts()
    }, [page, search])
    
    const scrollEvent = () => {
        const { scrollTop, clientHeight, offsetHeight } = document.documentElement
    
        if (window.innerWidth < 768 && (scrollTop + clientHeight + 50) >= offsetHeight) {
          setPage(page + 1)
        } else if ((scrollTop + clientHeight + 100) >= offsetHeight) {
          setPage(page + 1)
        }
      }

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)

        return () => {
            window.removeEventListener('scroll', scrollEvent)
        }
    }, [])

    const _fetchLatestPosts = () => {
        setLatestPostLoading(true)
        const data = []
        fetchLatestPostsWithPagination({ page, docLimit: 30, userCountry: country }).then(res => {
          res.forEach((item) => {
            data.push({ ...item.data(), id: item.id })
          })
        }).then(() => {
          setLatestPostLoading(false)
          setList([...list, ...data])
        }).catch(() => {
          setLatestPostLoading(false)
        })
      }

    return (
        <AppLayout>
           <Helmet>
        <title>View all dresses | dresez.com</title>
      </Helmet>
            <div className='w-full flex items-center flex-col'>
                <div className='max-w-[1400px] w-full px-2'>
                    <div className='py-4'>
                        {/* <div className="mb-3">
                            <label for="exampleFormControlInput1" className="form-label font-bold">Search</label>
                            <input className="form-control" id="exampleFormControlInput1" placeholder="Red frock" />
                        </div> */}

                        <div className='mt-2 flex flex-wrap gap-[1rem] justify-evenly'>
                            {
                                list?.map((item, i) => <PostHomeCard onClick={() => navigateTo(`post/${item?.id}`)} description={item?.description} id={item.id} image={item?.images?.[0]} key={i} title={item?.title} condition={item?.condition} />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}
