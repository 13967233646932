import * as fire from 'firebase/auth'
import { store } from '../../../store'
import { ANONYMOUS_LOGIN_FAILED, ANONYMOUS_LOGIN_REQUESTED, ANONYMOUS_LOGIN_SUCCEEDED, COUNTRY, LANGUAGE } from './action'

export const anonymousLogin = async () => {
    store.dispatch({ type: ANONYMOUS_LOGIN_REQUESTED })
    try {
        const data = await fire.signInAnonymously(fire.getAuth())
        store.dispatch({ type: ANONYMOUS_LOGIN_SUCCEEDED, data: data.user })
    }
    catch (e) {
        store.dispatch({ type: ANONYMOUS_LOGIN_FAILED, data: e.message })
    }
}

export const setCountry = (data) => {
    store.dispatch({ type: COUNTRY, data })
}

export const setLanguage = (data) => {
    store.dispatch({ type: LANGUAGE, data })
}