import React from "react";
import { AppLayout } from "../../Layout/AppLayout";
import { useHistory } from "react-router-dom";
import { commonTranslations } from "../../translation/commonTranslations";
import { Helmet } from "react-helmet";

export default function TermsPage() {
    const history = useHistory()
  const navigateTo = (path, params =  {}) => {
    const country = window.localStorage.getItem('country')
    const language = window.localStorage.getItem('language')
    history.push(`/${country}/${language}/${path}`, params)
  }
  return (
    <AppLayout back navigateTo={navigateTo} selected={1}>
       <Helmet>
        <title>Terms | dresez.com</title>
      </Helmet>
      <div className="w-full flex items-center flex-col">
        <div className="w-full max-w-[1400px] p-2 mt-2">
          <p className="text-[24px] font-bold">{commonTranslations.termsAndConditions}</p>
        </div>
      </div>
    </AppLayout>
  );
}
