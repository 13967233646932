import { setKey } from "@assetcrush/reactjs-sdk";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import FullPageLoading from "./components/FullPageLoading/FullPageLoading";
import Snackbar from "./components/snackbar";
import { anonymousLogin } from "./containers/CountryPage/sagas/saga";
import { getUser } from "./containers/SignupPage/sagas/saga";
import Routes from "./routes";
import fetchTranslation from "./translation/sagas";
function App() {
  const { messages, locale } = useSelector((state) => state.intl);
  const { isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    setKey('test')
    fetchTranslation();
    const id = window.localStorage.getItem('userId')
    getUser({ userId: id, errCallback: () => anonymousLogin() });
  }, []);

  if (isLoading) {
    return (
      <IntlProvider messages={messages} locale={locale}>
        <FullPageLoading />
      </IntlProvider>
    );
  }

  return (
    <IntlProvider messages={messages} locale={locale}>
      <Snackbar />
      {process.env.REACT_APP_ENV === 'STAGE' && <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>}
      <Routes />
    </IntlProvider>
  );
}

export default App;

