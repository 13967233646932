/* eslint-disable jsx-a11y/alt-text */
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationList,
  Conversation,
  Avatar,
  ConversationHeader,
  InfoButton,
  Loader,
} from "@chatscope/chat-ui-kit-react";
import Logo from '../../assets/images/logo.png'
import { useWindowSize } from "../../utilities/useWindowSize";
import { useParams, Link } from "react-router-dom";
import conversationPlaceholder from "../../assets/images/conversationPlaceholder.png";
import singleTick from "../../assets/images/singleTick.png";
import doubleTick from "../../assets/images/doubleTick.png";
import "./styles.scss";
import {
  createMessageInChat,
  fetchChatMessages,
  fetchRealTimeChatMessages,
  fetchRealTimeConversation,
  fetchSingleConversation,
  fetchUserConversations,
  updateMessageStatus,
} from "./sagas/saga";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import { getUserDetails } from "../PostDetailPage/saga/saga";
import { userPlaceholderImage } from "../../utilities/random";
import { useNavigateTo } from "../../utilities/routing";
import {
  chatConstants,
  useFetchLatestConversations,
  useFetchLatestMessage,
  useFetchUpdatedConversations,
} from "../../utilities/chatUtils";
import { fetchSinglePost } from "../AddPostPage/sagas/saga";
import { Timestamp } from "@firebase/firestore";
import { placeholderImage } from "../../utilities/images";
import { useRef } from "react";
import * as fireAuth from "firebase/auth";
import { AppLayout } from "../../Layout/AppLayout";

export const ChatPage = () => {
  const { navigateTo } = useNavigateTo();
  const currentUser = useSelector((state) => state.user);
  const firebaseUser = currentUser?.user?.id || fireAuth.getAuth().currentUser.uid
  const { chatId = "", country, language } = useParams(); 
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`
  const mobileSize = 640;
  const size = useWindowSize();
  const [page, setPage] = useState(0);
  const [chatPage, setChatPage] = useState(0)
  const [conversationList, setConversationList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [conversationLoading, setConversationLoading] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [lastViewedPost, setLastViewedPost] = useState({});
  const [message, setMessage] = useState("");
  const { lastMessage: newMessage } = useFetchLatestMessage(chatId);
  const { list: latestConversation } = useFetchLatestConversations(
    firebaseUser
  );
  const { list: updatedConversationList } = useFetchUpdatedConversations({
    userId: firebaseUser,
    conversationList: conversationList,
    selectedConversation: selectedConversation,
  });
  const msgListRef = useRef();

  const onChatPageChange = () => setChatPage(chatPage + 1)
  const onConversationPageChange = () => setPage(page + 1)

  useEffect(() => {
    setConversationList(updatedConversationList);
  }, [updatedConversationList]);

  const _fetchUserConversationList = () => {
    setConversationLoading(true);
    fetchUserConversations({
      page,
      docLimit: 20,
      userId: firebaseUser,
    })
      .then((data) => {
        setConversationList([...conversationList, ...data]);
        setConversationLoading(false);
      })
      .catch((e) => {
        setConversationLoading(false);
      });
  };

  useEffect(() => {
    _fetchUserConversationList();
  }, [page]);

  useEffect(() => {
    msgListRef.current?.scrollToBottom()
  }, [messageList]);

  useEffect(() => {
    if (chatId) {
      fetchSingleConversation({ chatId, userId: firebaseUser }).then(
        (res) => {
          setSelectedConversation(res);
          fetchChatMessages({ page: 0, docLimit: 50, chatId }).then(
            (messages) => {
              const msgs = [...messages, ...messageList.reverse()]
              setMessageList(msgs.reverse());
            }
          );
        }
      );
      updateMessageStatus({ chatId, recieverId: firebaseUser });
    }
  }, [chatId, chatPage]);

  useEffect(() => {
    if (selectedConversation?.lastViewedPostId) {
      fetchSinglePost({ postId: selectedConversation?.lastViewedPostId }).then(
        (res) => {
          setLastViewedPost(res);
        }
      );
    }
  }, [selectedConversation]);

  useEffect(() => {
    const messages = [...messageList];
    const checkPrevious =
      messages.filter((item) => item.id === newMessage.id).length > 0;
    if (checkPrevious) {
      const id = messages.findIndex((item) => item.id === newMessage.id);
      messages[id] = newMessage;
      setMessageList(messages);
      return;
    }
    messages.push(newMessage);
    setMessageList(messages);

    if (chatId && newMessage) {
      updateMessageStatus({ chatId, recieverId: firebaseUser });
    }
  }, [newMessage]);

  useEffect(() => {
    if (!latestConversation?.id) return;
    const list = [...conversationList];
    const checkPrevious =
      list.filter((item) => item?.id === latestConversation?.id).length > 0;
    if (checkPrevious) {
      return;
    }
    list.push(latestConversation);
    setConversationList(list);
  }, [latestConversation]);


  const onConversationClick = (id) => {
    if (chatId === id) return
    setMessageList([])
    setSelectedConversation({});
    navigateTo(`chats/${id}`);
  };

  const onPostnameClick = (id) => {
    navigateTo(`post/${id}`);
  };

  const onGoToUserProfile = () =>
    navigateTo(`user/${selectedConversation?.chatUser?.id}`);

  const onCreateMessage = () => {
    createMessageInChat({
      chatId: selectedConversation?.id,
      type: "text",
      value: message,
      senderId: firebaseUser,
      recieverId: selectedConversation?.chatUser?.id,
    })
    msgListRef.current?.scrollToBottom()
    setMessage("");
  };

  if (conversationLoading && page === 0) {
    return <FullPageLoading />;
  }

  if (chatId) {
    return (
      <div style={{ height: window.innerHeight }} className="relative">
        <MainContainer>
          {size.width >= mobileSize && (
            <ConversationList
              style={{ flexBasis: size.width <= mobileSize ? "100%" : 350 }}
              className={`w-full chat-message-list`}
              onYReachEnd={onConversationPageChange}
            >
              {/* user header */}
              <ConversationHeader>
                {size.width <= mobileSize && (
                  <ConversationHeader.Back onClick={() => navigateTo("")} />
                )}
                <ConversationHeader.Content>
                <Link to={navTo('')}>
                <div className='flex cursor-pointer'>
                        <img src={Logo} className='w-[30px] h-[40px] object-contain' />
                        <div className='ml-2'>
                            <h2 className='text-[20px] text-black'>dresez.com</h2>
                            <h4 className='text-[14px] text-black'>#buy #sell #dresses</h4>
                        </div>
                        </div>
                        </Link>
                </ConversationHeader.Content>
              </ConversationHeader>
              {/* chat list */}
              {conversationList?.map(({ chatUser = {}, ...item }, i) => (
                <Conversation
                  key={i}
                  onClick={() => onConversationClick(item?.id)}
                  name={chatUser?.name || 'dresez user'}
                  info={item?.lastMessage || "Start chatting"}
                  active={chatId === item?.id}
                  unreadCnt={item?.unreadCount}
                >
                  <Avatar src={chatUser?.userImage || userPlaceholderImage} />
                </Conversation>
              ))}
              {conversationLoading && <center><br /><Loader />
              <br />
              </center>}
            </ConversationList>
          )}

          {selectedConversation?.id ? (
            <ChatContainer>
              <ConversationHeader>
                {size.width <= mobileSize && (
                  <ConversationHeader.Back
                    onClick={() => navigateTo("chats")}
                  />
                )}
                <Avatar
                  src={
                    selectedConversation?.chatUser?.userImage ||
                    userPlaceholderImage
                  }
                />
                <ConversationHeader.Content
                  userName={selectedConversation?.chatUser?.name || 'dresez user'}
                />
                <ConversationHeader.Actions>
                  <InfoButton onClick={onGoToUserProfile} title="Show info" />
                </ConversationHeader.Actions>
              </ConversationHeader>
              <MessageList
                ref={msgListRef}
                autoScrollToBottomOnMount={true}
                autoScrollToBottom={true}
                className={`chat-message-list`}
                // scrollBehavior='smooth'
                // onYReachStart={onChatPageChange}
              >
                <div className="w-100 p-3 bg-[#F6FBFF] fixed ml-[-12px] top-[60px]">
                  <div className="flex items-center">
                    <img
                      className="w-[50px] h-[50px] rounded"
                      src={lastViewedPost?.images?.[0] || placeholderImage}
                    />
                    <div className=" ml-4">
                      <p
                        onClick={() =>
                          onPostnameClick(
                            selectedConversation?.lastViewedPostId
                          )
                        }
                        className="cursor-pointer underline font-bold text-black"
                      >
                        {lastViewedPost?.title}
                      </p>
                      <p className="text-black">
                        {lastViewedPost?.currencyCode} {lastViewedPost?.price}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-[100px]" />
                {messageList?.map((item, index) => (
                  <Message
                    key={index}
                    model={{
                      message: item?.value,
                      sentTime: "just now",
                      direction:
                        item?.senderId === firebaseUser
                          ? "outgoing"
                          : "incoming",
                      position: "normal",
                      type: "text",
                    }}
                  >
                    {item?.senderId === firebaseUser && (
                      <Message.Footer>
                        <div className="flex items-end justify-end w-full">
                          {item?.status === chatConstants.delivered ? (
                            <img
                              className="w-[10px] h-[10px]"
                              src={singleTick}
                            />
                          ) : item?.status === chatConstants.seen ? (
                            <img
                              className="w-[10px] h-[10px]"
                              src={doubleTick}
                            />
                          ) : null}
                        </div>
                      </Message.Footer>
                    )}
                  </Message>
                ))}
              </MessageList>
              <MessageInput
                attachButton={false}
                placeholder="Type message here"
                onSend={onCreateMessage}
                onChange={(val, text) => setMessage(text)}
                value={message}
              />
            </ChatContainer>
          ) : (
            <FullPageLoading />
          )}
        </MainContainer>
      </div>
    );
  }
  return (
    <AppLayout noHeader selected={3}>
    <div style={{ height: window.innerHeight }} className="relative">
      <MainContainer>
        <ConversationList
          style={{ flexBasis: size.width <= mobileSize ? "100%" : 350 }}
          className={`w-full chat-message-list`}
        >
          {/* user header */}
          <ConversationHeader>
            <ConversationHeader.Content>
            <Link to={navTo('')}>
                <div className='flex cursor-pointer'>
                        <img src={Logo} className='w-[30px] h-[40px] object-contain' />
                        <div className='ml-2'>
                            <h2 className='text-[20px] text-black'>dresez.com</h2>
                            <h4 className='text-[14px] text-black'>#buy #sell #dresses</h4>
                        </div>
                        </div>
                        </Link>
            </ConversationHeader.Content>
          </ConversationHeader>
          {/* chat list */}
          {conversationList?.map(({ chatUser = {}, ...item }, i) => (
            <Conversation
              onClick={() => onConversationClick(item?.id)}
              name={chatUser?.name || 'dresez user'}
              info={item?.lastMessage || "Start chatting"}
              unreadCnt={item?.unreadCount}
              key={i}
            >
              <Avatar src={chatUser?.userImage || userPlaceholderImage} />
            </Conversation>
          ))}
        </ConversationList>
        {size.width >= mobileSize && (
          <div className="w-full flex items-center justify-center">
            <img
              src={conversationPlaceholder}
              className="w-[100px] h-[100px] object-contain"
            />
          </div>
        )}
      </MainContainer>
    </div>
    </AppLayout>
  );
};
