import * as fireStore from "firebase/firestore";
import { showNotification } from "../../../components/snackbar/saga";
import { chatIdGenerator } from "../../../utilities/chatUtils";
import { db } from "../../../utilities/firebase";

export const getUserDetails = async ({ userId, otherData = {} }) => {
   const user  = await fireStore.getDoc(fireStore.doc(db, "users", userId))
   return Promise.resolve({ ...user.data(), otherData })
}

export const createChat = ({ senderId, recieverId, postId }) => new Promise((resolve, reject) => {
   fireStore
   .getDoc(fireStore.doc(db, "users", senderId)).then((res) => {
      if (res.exists()){
         const data = {
            users: [senderId, recieverId],
            createdAt: new Date(),
            updatedAt: new Date(),
            lastViewedPostId: postId,
         }
         const chatId = chatIdGenerator({ recieverId, senderId })
         fireStore
         .setDoc(fireStore.doc(db, "conversation", chatId), data)
         .then(() => {
            resolve(chatId);
         })
         .catch((e) => {
           showNotification({ isError: true, message: e.message });
           reject();
         });
         return
      }
      fireStore
      .setDoc(fireStore.doc(db, "users", senderId), { id: senderId, name: 'dresez user', isAnonymousUser: true })
      .then(() => {
         const data = {
            users: [senderId, recieverId],
            createdAt: new Date(),
            updatedAt: new Date(),
            lastViewedPostId: postId,
         }
         const chatId = chatIdGenerator({ recieverId, senderId })
         fireStore
         .setDoc(fireStore.doc(db, "conversation", chatId), data)
         .then(() => {
            resolve(chatId);
         })
         .catch((e) => {
           showNotification({ isError: true, message: e.message });
           reject();
         });
      })
   })
})