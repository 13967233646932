import * as React from 'react';
import Home from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import { Fab } from '@mui/material';
import * as fireAuth from "firebase/auth";
import { useSelector } from 'react-redux';
import { useFetchIndication } from '../../utilities/chatUtils';

export const AppTabs = ({ navigateTo, selected }) => {
  const user = useSelector(state => state.user.user)
  const [conversationIndication, setConversationIndication] = React.useState({});
  const firebaseUser = user?.id || fireAuth.getAuth().currentUser.uid
  const { list: latestConversation } = useFetchIndication(
    firebaseUser
  );
  const StyledFab = styled(Fab)({
    marginTop: -50,
    cursor: 'pointer',
    width: 40,
    height: 40
  });

  React.useEffect(() => {
    setConversationIndication(latestConversation)
  }, [latestConversation]);

  return (
    <>
    <div className='w-full h-[53px] flex items-center justify-center'>
      <div className='flex max-w-[1400px] w-full'>
      <div onClick={() => navigateTo('')} className={`w-[33%] h-[53px] flex items-center justify-end flex-col border-b-4 border-b-[${selected === 0 ? '#8338EC' : 'white'}] cursor-pointer hover:bg-slate-100`}>
        <Home style={{ color: selected === 0 ? '#8338EC' : 'black' }} fontSize='small' />
        <p style={{ color: selected === 0 ? '#8338EC' : 'black' }} className='text-[14px] m-0'>Home</p>
      </div>
      <div onClick={() => navigateTo('posts')} className={`w-[33%] h-[53px] flex items-center justify-end flex-col border-b-4 border-b-[${selected === 1 ? '#8338EC' : 'white'}] cursor-pointer hover:bg-slate-100`}>
        <FavoriteIcon style={{ color: selected === 1 ? '#8338EC' : 'black' }} fontSize='small' />
        <p style={{ color: selected === 1 ? '#8338EC' : 'black' }} className='text-[14px] m-0'>My Posts</p>
      </div>
      <div className='w-[33%] h-[53px] flex items-center justify-center flex-col'>
      <StyledFab onClick={() => navigateTo('add-post')} color="secondary">
        <AddIcon />
      </StyledFab>
      </div>
      <div onClick={() => navigateTo('profile')} className={`w-[33%] h-[53px] flex items-center justify-end flex-col border-b-4 border-b-[${selected === 2 ? '#8338EC' : 'white'}] cursor-pointer hover:bg-slate-100`}>
        <PersonIcon style={{ color: selected === 2 ? '#8338EC' : 'black' }} fontSize='small' />
        <p style={{ color: selected === 2 ? '#8338EC' : 'black' }} className='text-[14px] m-0'>My Profile</p>
        {!user?.isProfileCompleted && <div className='absolute w-[8px] h-[8px] rounded-full bg-red-500 ml-3 mb-8' />}
      </div>
      <div onClick={() => navigateTo('chats')} className={`w-[33%] h-[53px] flex items-center justify-end flex-col border-b-4 border-b-[${selected === 3 ? '#8338EC' : 'white'}] cursor-pointer hover:bg-slate-100`}>
        <ChatIcon style={{ color: selected === 3 ? '#8338EC' : 'black' }} fontSize='small' />
        <p style={{ color: selected === 3 ? '#8338EC' : 'black' }} className='text-[14px] m-0'>Chats</p>
        {conversationIndication?.conversationId && <div className='absolute w-[8px] h-[8px] rounded-full bg-red-500 ml-3 mb-8' />}
      </div>
      </div>
    </div>
    </>
  );
}
