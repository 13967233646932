import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { Field } from "redux-form";
import GroupDropDown from "../../components/GoupField/GroupDropdown";
import GroupField from "../../components/GoupField/GroupField";
import { commonTranslations } from "../../translation/commonTranslations";
import addIcon from "../../assets/images/addIcon.png";
import redCross from "../../assets/images/redCross.png";
import { useRef } from "react";
import { addPost, fetchSinglePost, imageUploader } from "./sagas/saga";
import { useState } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import ErrorPage from '../../components/ErrorPage/errorBoundary'
import DescriptionEditor from "../../components/DescriptionEditor/DescriptionEditor";
import Label from "../../components/Label/Label";
import { createEditorStateWithText } from "@draft-js-plugins/editor";
import { currencies } from "../../utilities/currencies";
import { AppLayout } from "../../Layout/AppLayout";
import * as fireStore from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { Helmet } from "react-helmet";

const AddPostPage = injectIntl(({ handleSubmit, intl, change, isEdit }) => {
  const history = useHistory();
  const { formatMessage } = intl;
  const condition = [
    { name: "Used", value: "used" },
    { name: "New", value: "new" },
    { name: "Pre-loved", value: "pre-loved" },
  ];
  const gender = [
    { name: "For him", value: "men" },
    { name: "For her", value: "women" },
    { name: "Others", value: "other" },
  ];
  const imageRef = useRef();
  const [images, setImages] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [editWholeLoading, setEditWholeLoading] = useState(false)
  const [wholePageError, setWholePageError] = useState('')
  const [editorState, seteditorState] = useState(createEditorStateWithText(''))
  const [currencyCode, setCurrencyCode] = useState('usd')
  const country = window.localStorage.getItem('country')
  const [createdAt, setCreatedAt] = useState(Timestamp.now())
  const params = useParams()

  const toggleDiscountBox = () => {
    setIsDiscounted(!isDiscounted)
    change('discount', '')
  }

  useEffect(() => {
    if (isEdit) return
    setCurrencyCode(currencies.find(item => item.countryCode?.toLowerCase() === country?.toLowerCase())?.currencyCode)
  }, [country])

  useEffect(() => {
    if (isEdit) {
      setEditWholeLoading(true)
      fetchSinglePost({ postId: params?.postId }).then(res => {
        const {
          title = "",
          description = "",
          gender = "",
          condition = "",
          price = "",
          discount = "",
          images = [],
          id = '',
          currencyCode = '',
          createdAt = Timestamp.now()
        } = res;

        setCreatedAt(createdAt)
        change('title', title)
        seteditorState(createEditorStateWithText(description))
        change('gender', gender)
        change('price', price)
        change('condition', condition)
        change('discount', discount)
        setCurrencyCode(currencyCode || currencies.find(item => item.countryCode?.toLowerCase() === country?.toLowerCase())?.currencyCode)
        if (discount) {
          setIsDiscounted(true)
        }
        setImages(images.map(item => ({ url: item })))
        setImagesToUpload(images.map(item => ({ url: item })))
        setEditWholeLoading(false)
      }).catch(e => {
        setEditWholeLoading(false)
        setWholePageError(e)
      })
    }
  }, [isEdit])

  const onPostAd = (val) => {
    const { title, gender, condition, price, discount = '' } = val;
    setIsLoading(true);
    addPost({
      title,
      description: editorState.getCurrentContent().getPlainText(),
      gender,
      condition,
      userId: user?.id,
      images: imagesToUpload.map((item) => item.url),
      history,
      price,
      discount,
      isEdit,
      postId: params?.postId,
      hashTags: editorState.getCurrentContent().getPlainText().replace(/\n/g, " ").split(" ").filter(item => item?.startsWith('#')),
      currencyCode,
      country: window.localStorage.getItem('country'),
      createdAt
    })
      .then(() => {
        const country = window.localStorage.getItem('country')
        const language = window.localStorage.getItem('language')
        history.push(`/${country}/${language}/posts`)
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleFile = (e, index) => {
    const file = e.target.files[0];
    imageUploader({
      file,
      setIsloading: (loader) => {
        if (!loader) return;
        const list = [...images];
        setImages([...list, { url: window.URL.createObjectURL(file) }]);
      },
      setIsSuccess: (url) => {
        const list = [...imagesToUpload];
        setImagesToUpload([...list, { url }]);
      }
    });
  };

  const onDeleteImage = (index) => {
    const list = [...images];
    setImages(list.filter((item, i) => i !== index));
    setImagesToUpload(list.filter((item, i) => i !== index));
  };

  if (editWholeLoading) {
    return <FullPageLoading />
  }

  if (wholePageError) {
    return <ErrorPage message={wholePageError} />
  }

  return (
    <AppLayout>
       <Helmet>
        <title>Post a dress | dresez.com</title>
      </Helmet>
      <div
        style={{ minHeight: window.innerHeight }}
        className="w-full items-center justify-center flex flex-col py-8"
      >
        <center>
          <Typography paragraph style={{ fontWeight: "600" }} variant="h5">
            {isEdit ? 'EDIT YOUR POST' : 'POST YOUR AD'}
          </Typography>
        </center>
        <div className="w-[90%] max-w-[1000px] border rounded md:p-8 p-4">
          <form onSubmit={handleSubmit(onPostAd)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="flex flex-wrap gap-6">
                  {images.map((item, i) => (
                    <div
                      key={i}
                      className="w-[100px] h-[100px] rounded border-2 border-slate-800 flex items-center justify-center cursor-pointer"
                    >
                      <div className="image relative">
                        <img
                          src={item?.isLoading ? item?.demoPath : item?.url}
                          className={"w-[95px] h-[95px] object-contain"}
                          alt={"add"}
                        />
                        <div
                          onClick={() => onDeleteImage(i)}
                          className="w-[98px] h-[98px] backDrop"
                        >
                          <img
                            src={redCross}
                            className={"w-[30px] h-[30px] object-contain"}
                            alt={"close"}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    onClick={() => imageRef.current.click()}
                    className="w-[100px] h-[100px] rounded border-2 border-slate-800 flex items-center justify-center cursor-pointer"
                  >
                    <img
                        src={addIcon}
                        className={"w-[30px] h-[30px] object-contain"}
                        alt={"add"}
                      />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="title"
                  component={GroupField}
                  placeholder={`${formatMessage(commonTranslations.addTitleHere.props)}`}
                  label={`${formatMessage(commonTranslations.title.props)}`}
                  type="text"
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item>
                  <Label title={`${formatMessage(commonTranslations.description.props)}`} />
                </Grid>
                <DescriptionEditor placeholder={`${formatMessage(commonTranslations.addDescriptionHere.props)}`} editorState={editorState} seteditorState={seteditorState} className={'border mt-2 border-[#D0D0D0] py-[1rem] rounded-[6px] px-[1rem] h-[200px] overflow-y-auto cursor-text'} />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="gender"
                  component={GroupDropDown}
                  label={`${formatMessage(commonTranslations.gender.props)}`}
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                  menus={gender}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="condition"
                  component={GroupDropDown}
                  label={`${formatMessage(commonTranslations.condition.props)}`}
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                  menus={condition}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="price"
                  component={GroupField}
                  label={`${formatMessage(commonTranslations.price.props)}`}
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                  type={"number"}
                  isPrice
                  currencyCode={currencyCode}
                  setCurrencyCode={(val) => setCurrencyCode(val)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDiscounted}
                      onChange={toggleDiscountBox}
                    />
                  }
                  label={`${formatMessage(
                    commonTranslations.discountedPrice.props
                  )}`}
                />
              </Grid>
              {isDiscounted && (
                <Grid item xs={12}>
                  <Field
                    name="discount"
                    component={GroupField}
                    label={`${formatMessage(commonTranslations.discountedPrice.props)}`}
                    border="1px solid #D0D0D0"
                    brRadius="6px"
                    height={48}
                    type={"number"}
                    isPrice
                    currencyCode={currencyCode}
                    setCurrencyCode={(val) => setCurrencyCode(val)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <center>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isEdit ? "Update" : commonTranslations.postNow}
                    </Button>
                  )}
                </center>
              </Grid>
            </Grid>
            <input
              accept="image/png, image/jpeg"
              ref={imageRef}
              onChange={(e) =>
                handleFile(e, images.length === 0 ? 0 : images.length)
              }
              type={"file"}
              className={"hidden"}
            />
          </form>
        </div>
      </div>
    </AppLayout>
  );
});

export default AddPostPage;
