import React from "react";
import { placeholderImage } from '../../utilities/images'
import { ImageCrush } from '@assetcrush/reactjs-sdk'

export default function PostHomeCard({ title, condition, image, onClick }) {

    return (
        <div onClick={onClick} className="p-3 bg-white min-w-[250px] w-[250px] border rounded mt-3 cursor-pointer">
            <img
                className="w-full h-[200px] rounded myPostImage object-contain bg-gray-100"
                src={image || placeholderImage}
                width={200}
                height={200}
            />
            <div className="flex flex-1 flex-col justify-between">
                <div className="w-full flex flex-col flex-1">
                    <p className="text-[18px] text-black font-bold text-left mt-2">
                        {title}
                    </p>
                </div>
                <p className="text-[14px] text-[rgba(0,47,52,.64)] text-left mt-2 flex flex-1 items-end justify-start">
                    {condition}
                </p>
            </div>
        </div>
    );
}