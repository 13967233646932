import { Popover } from "@material-ui/core";
import React, { useState } from "react";
import menu from '../../assets/images/menuDots.png'
import "./styles.scss";
import { placeholderImage } from '../../utilities/images'
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { noop } from "lodash";
import { onDeletePost } from "../DeleteModal/sagas/saga";
import { useHistory } from "react-router-dom";

export default function PostCard({ title, condition, image, id, fetchPosts, description, isOthers, onClick = () => null  }) {
  const history = useHistory()
  const [isDelete, setIsDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const menus = [
    {name:'delete', value:'delete', onClick: () => setIsDelete(true) },
    {name:'edit', value:'edit', onClick: () => setEditPost()},
  ]

  const onDelete = () => {
    setIsLoading(true)
    onDeletePost(id).then(() => {
      setIsLoading(false)
      fetchPosts()
      setIsDelete(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }

  const setEditPost = () => {
    const country = window.localStorage.getItem('country')
    const language = window.localStorage.getItem('language')
    history.push(`/${country}/${language}/edit/${id}`)
  }

  return (
    <>
    <div onClick={onClick} className="w-full p-3 bg-white myPostBox max-w-[800px] border rounded mt-3 cursor-pointer">
      <img
        className="w-[150px] h-[150px] rounded myPostImage object-contain bg-gray-100"
        src={image || placeholderImage}
      />
      <div className="sm:ml-4 flex flex-1 flex-col justify-between myPostTitle">
        <div className="flex justify-between">
          <div className="w-full">
          <p className="text-[24px] text-black font-bold text-left">
            {title}
          </p>
          <p className="text-[14px] text-gray-400 text-left">
            {description?.length > 250 ? `${description.slice(0,250)}...` : description}
          </p>
          </div>
            {!isOthers ? <PostMenu onDelete={() => setIsDelete(true)} menus={menus} /> : <div />}
        </div>
        <p className="text-[14px] text-[rgba(0,47,52,.64)] text-left mt-2">
          {condition}
        </p>
      </div>
    </div>
    <DeleteModal name={title} image={image || placeholderImage} isLoading={isLoading} onDelete={onDelete} visible={isDelete} onCLose={() => setIsDelete(false)} />
    </>
  );
}

export const PostMenu = ({ menus, onCLick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
  <div style={{ width: 30 }} className="w-[30px] h-[20px]">
    <img onClick={handleClick} aria-describedby={id} src={menu} className='cursor-pointer w-[30px] h-[20px] object-contain' />
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
       <div className="p-2 rounded">
         {menus?.map((item,i) => (
          <div onClick={item?.onClick || noop} className="px-2 py-2 hover:bg-slate-200 min-w-[100px] cursor-pointer">
            <p>{item.name}</p>
            </div>
         ))}
       </div>
      </Popover>
  </div>
)
  }