import { db } from "../../../utilities/firebase";
import * as fireStore from "firebase/firestore";
import { showNotification } from "../../../components/snackbar/saga";

export const fetchUserPosts = ({
    userId,
    limit = 999
  }) =>
    new Promise((resolve, reject) => {
        fireStore.getDocs(fireStore
            .query(fireStore.collection(db, "posts"), fireStore.where("userId", "==", userId), fireStore.limit(limit)))
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          showNotification({ isError: true, message: e.message });
          reject();
        });
    });