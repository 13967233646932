import produce from 'immer'
import {
    ANONYMOUS_LOGIN_REQUESTED,
    ANONYMOUS_LOGIN_SUCCEEDED,
    ANONYMOUS_LOGIN_FAILED,
    COUNTRY,
    LANGUAGE
} from './action'

const defaultState = {
  isLoading: false,
  isError: false,
  user: {},
  error: '',
  path: {
    country: '',
    language: ''
  }
}

const authReducer = produce((state = defaultState, action) => {
  const { type, data } = action

  switch (type) {
    case LANGUAGE: {
      state.path.language = data
      break;
    }
    case COUNTRY: {
      state.path.country = data
      break;
    }
   case ANONYMOUS_LOGIN_REQUESTED: {
       state.isLoading = true
       state.isError = false
       state.error = ''
       state.user = {}
       break;
   }
   case ANONYMOUS_LOGIN_SUCCEEDED: {
       state.isLoading = false
       state.isError = false
       state.error = ''
       state.user = data
       break;
   }
   case ANONYMOUS_LOGIN_FAILED: {
       state.isLoading = false
       state.isError = true
       state.user = {}
       state.error = data
       break;
   }
    default:
      return state
  }
}, defaultState)

export default authReducer