import React from 'react'
import { ArrowBack, Settings } from '@mui/icons-material'
import { Link, useHistory, useParams } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

export default function Header({ back }) {
  const history = useHistory()
  const {country, language} = useParams();
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`
  const user = useSelector((state) => state.user?.user);
    return (
        <div className='w-full'>
            <nav class="navbar navbar-expand-lg bg-light flex items-center justify-center">
                <div className="container-fluid max-w-[1400px] w-full">
                    <div className='flex items-center'>
                        {back && <ArrowBack onClick={() => history.goBack()} className='mr-2 cursor-pointer' />}
                        <Link to={navTo('')}>
                        <div className='flex cursor-pointer'>
                        <img src={Logo} className='w-[30px] h-[40px] object-contain' />
                        <div className='ml-2'>
                            <h2 className='text-[20px] text-black'>dresez.com</h2>
                            <h4 className='text-[14px] text-black'>#buy #sell #dresses</h4>
                        </div>
                        </div>
                        </Link>
                    </div>
                    <div className='flex items-center justify-center'>
                        {!user?.id && 
                    <Button
                      variant="contained"
                      onClick={() => history.push(`/${country}/${language}/signin`)}
                    >
                      Login
                    </Button>}
                    <Link to={navTo('settings')}>
                        <Settings className='cursor-pointer ml-4' />
                    </Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}
