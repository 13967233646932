import { Grid } from '@material-ui/core'
import Label from '../Label/Label'
import Input from '../Input/Input'
import Error from '../error/error'

export default function GroupField (props) {
  const { label, required } = props
  const { error, submitFailed } = props.meta

  return (
    <>
      <Grid container spacing={1} item direction='column'>
        {label && (
          <Grid item>
            <Label title={label} required={!!required || false} />
          </Grid>
        )}

        <Grid item>
          <Input {...props} />
        </Grid>
      </Grid>
      {submitFailed && error && <Error error={error} />}
    </>
  )
}
