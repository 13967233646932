import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppLayout } from "../../Layout/AppLayout";
import LanguageBar from "../../components/LanguageBar/LanguageBar";
import { Logout, Login, DeleteForever } from "@mui/icons-material";
import { logoutUser, updateUserProfile } from "../SignupPage/sagas/saga";
import { Button } from "@mui/material";
import { userCountry, userLanguage } from "../../utilities/random";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DeleteModal } from "../../components/DeleteModal/DeleteModal";

export default function SettingsPage() {
    const history = useHistory()
    const [isDelete, setIsDelete] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { user = {} } = useSelector((state) => state.user);
  const onGoToLogin = () => {
    history.push(`/${userCountry}/${userLanguage}/signin`)
  }
  const navigateTo = (path, params =  {}) => {
    const country = window.localStorage.getItem('country')
    const language = window.localStorage.getItem('language')
    history.push(`/${country}/${language}/${path}`, params)
  }

  const onDeleteAccount = () => {
    setIsDeleteLoading(true)
    updateUserProfile({ userId: user?.id, userData: { isDeactivated: true } })
      .then(() => {
        setIsDeleteLoading(false);
      })
      .catch(() => {
        setIsDeleteLoading(false);
      });
  }

  return (
    <AppLayout back>
       <Helmet>
        <title>Settings | dresez.com</title>
      </Helmet>
      <div className="w-full flex items-center flex-col">
        <div className="w-full max-w-[1400px] p-2 mt-2">
          <div className="max-w-[400px]">
          <LanguageBar />
          {user?.id ? (
            <div>
            <div
              onClick={() => logoutUser({ navigateTo })}
              style={{ border: "1px solid #d32f2f" }}
              className="w-full text-[#d32f2f] font-bold cursor-pointer rounded p-2 flex items-center mt-2 py-3"
            >
              <Logout color="error" className="mr-2" />
              Logout
            </div>

            <div
              onClick={() => setIsDelete(true)}
              style={{ border: "1px solid #d32f2f" }}
              className="w-full text-[#d32f2f] font-bold cursor-pointer rounded p-2 flex items-center mt-2 py-3"
            >
              <DeleteForever color="error" className="mr-2" />
              Delete my account
            </div>

            </div>
          ) : 
          <Button className="mt-2 cursor-pointer w-full" onClick={onGoToLogin} variant="outlined">
            <Login className="mr-2" />
            Login
            </Button>
          }
          </div>
        </div>
      </div>
      <DeleteModal heading={'Are u sure you want to dele your account ?'} isLoading={isDeleteLoading} onDelete={onDeleteAccount} visible={isDelete} onCLose={() => setIsDelete(false)} />
    </AppLayout>
  );
}
