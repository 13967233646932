import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import reducers from './reducers'

const reducer = combineReducers({ ...reducers })

const rootReducer = (defaultState, action) => {
  const state = defaultState
  return reducer(state, action)
}

const middlewares = []

const composeEnhancers = typeof window === 'object' &&
   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    })
  : compose

export const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(rootReducer)

export default store
