import React from "react";
import "./styles.scss";
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { Field, formValueSelector, getFormValues } from "redux-form";
import GroupField from "../../components/GoupField/GroupField";
import { commonTranslations } from "../../translation/commonTranslations";
import { Button, CircularProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import {
  loginUser,
  loginWithPhone,
  resendCode,
  resetEmail,
  verifyPhoneNumber,
} from "./sagas/saga";
import { useHistory, useParams } from "react-router-dom";
import { userLanguage } from "../../utilities/random";
import { useState } from "react";
import { useSelector } from "react-redux";
import Home from "@mui/icons-material/Home";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export const LoginPage = injectIntl(
  ({ handleSubmit, intl, isLoading, phone, emailReset = false }) => {
    const { formatMessage } = intl;
    const { country: userCountry } = useParams();
    const { values = {} } = useSelector((state) => state.form.signinForm);
    const history = useHistory();
    const [phoneLoading, setPhoneLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [isPhoneVerify, setIsPhoneVerify] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const navigateTo = (path, params = {}) => {
      history.push(`/${userCountry}/${userLanguage}/${path}`, params);
    };

    const onLogin = (val) => {
      loginUser({
        email: val.email,
        pass: val.password,
      });
    };

    const onResetEmail = (val) => {
      resetEmail({
        email: val.email,
        push: () => history.push(`/${userCountry}/${userLanguage}/signin/email`)
      });
    };

    const onGoToSignup = () => {
      history.push(`/${userCountry}/${userLanguage}/signup`);
    };

    useEffect(() => {
      if (values?.contactNumber) {
        setIsPhoneValid(
          isValidPhoneNumber(values?.contactNumber, userCountry?.toUpperCase())
        );
      }
    }, [values]);

    const onLoginWithPhone = () => {
      const phoneNumber = parsePhoneNumber(
        values?.contactNumber,
        userCountry?.toUpperCase()
      );
      setPhoneLoading(true);
      loginWithPhone({ phone: phoneNumber.formatInternational() })
        .then(() => {
          setIsPhoneVerify(true);
          setPhoneLoading(false);
        })
        .catch(() => {
          setIsPhoneVerify(false);
          setPhoneLoading(false);
        });
    };

    const onResendCode = () => {
      const phoneNumber = parsePhoneNumber(
        values?.contactNumber,
        userCountry?.toUpperCase()
      );
      resendCode({ phone: phoneNumber.formatInternational() });
    };

    const onverifyPhoneCode = () => {
      const phoneNumber = parsePhoneNumber(
        values?.contactNumber,
        userCountry?.toUpperCase()
      );
      setVerifyLoading(true);
      verifyPhoneNumber({
        code: values.verificationPhone,
        phone: phoneNumber.number,
      })
        .then(() => {
          setVerifyLoading(false);
        })
        .catch(() => {
          setVerifyLoading(false);
        });
    };
  
    if (emailReset) {
      return (
        <div style={{ height: window.innerHeight }} className="box bg-white">
          <Helmet>
            <title>Signin | dresez.com</title>
          </Helmet>
          <form onSubmit={handleSubmit(onResetEmail)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  component={GroupField}
                  placeholder={"johndoe@gmail.com"}
                  label={`${formatMessage(commonTranslations.email.props)}`}
                  type="text"
                  border="1px solid #D0D0D0"
                  brRadius="6px"
                  height={48}
                />
              </Grid>

              <Grid item xs={12}>
                      <center>
                      <Button
                            type="submit"
                            variant="contained"
                          >
                            Submit
                          </Button>
                      </center>
                    </Grid>
            </Grid>
          </form>
          <br />
          <center>
                <Button
                  onClick={() => navigateTo("")}
                  variant="contained"
                  endIcon={<Home fontSize="small" />}
                >
                  Go to home
                </Button>
              </center>
        </div>
      );
    }

    return (
      <div style={{ height: window.innerHeight }} className="box bg-white">
        <Helmet>
          <title>Signin | dresez.com</title>
        </Helmet>
        <div id="recaptcha-container"></div>
        {!isPhoneVerify ? (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <center>
                <Typography
                  paragraph
                  style={{ fontWeight: "600" }}
                  variant="h5"
                >
                  Sign in
                </Typography>
              </center>
              <form onSubmit={handleSubmit(onLogin)}>
                {!phone && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        component={GroupField}
                        placeholder={"johndoe@gmail.com"}
                        label={`${formatMessage(
                          commonTranslations.email.props
                        )}`}
                        type="text"
                        border="1px solid #D0D0D0"
                        brRadius="6px"
                        height={48}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        component={GroupField}
                        label={`${formatMessage(
                          commonTranslations.password.props
                        )}`}
                        border="1px solid #D0D0D0"
                        brRadius="6px"
                        height={48}
                        type={"password"}
                      />
                      <div className="flex items-end justify-end">
                        <p
                          onClick={() => navigateTo("signin/email/reset")}
                          className="text-[16px] font-bold text-center cursor-pointer text-[#0645AD] underline"
                        >
                          Forgot password?
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <center>
                        {isLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={isLoading}
                          >
                            {commonTranslations.signin}
                          </Button>
                        )}
                      </center>
                    </Grid>

                    <div className="w-full my-2">
                      <center>
                        <p
                          onClick={onGoToSignup}
                          className="text-[14px] font-bold cursor-pointer text-[#0645AD] underline"
                        >
                          {commonTranslations.dontHaveAccount} Signup
                        </p>
                      </center>
                    </div>
                    <div className="mt-4" />
                    <div className="w-full">
                      <center>
                        <p
                          onClick={() => navigateTo("signin")}
                          className="text-[16px] font-bold my-4 text-center cursor-pointer text-[#0645AD] underline"
                        >
                          {commonTranslations.signWithPhone}
                        </p>
                      </center>
                      <center>
                        <Button
                          onClick={() => navigateTo("")}
                          variant="contained"
                          endIcon={<Home fontSize="small" />}
                        >
                          Go to home
                        </Button>
                      </center>
                    </div>
                  </Grid>
                )}
                {phone && (
                  <Grid container xs={12}>
                    <Grid item xs={12}>
                      <Field
                        name="contactNumber"
                        component={GroupField}
                        placeholder={`${formatMessage(
                          commonTranslations.phoneNumberHere.props
                        )}`}
                        label={`${formatMessage(
                          commonTranslations.contact.props
                        )}`}
                        type="text"
                        border="1px solid #D0D0D0"
                        brRadius="6px"
                        height={48}
                      />
                    </Grid>
                    <div className="w-full my-2" />
                    <Grid item xs={12}>
                      <center>
                        {phoneLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            disabled={
                              isLoading ||
                              !values?.contactNumber ||
                              !isPhoneValid
                            }
                            onClick={onLoginWithPhone}
                          >
                            {commonTranslations.signinWithPhone}
                          </Button>
                        )}
                      </center>
                    </Grid>
                    <div className="mt-4" />
                    <div className="w-full">
                      <center>
                        <p
                          onClick={() => navigateTo("signin/email")}
                          className="text-[16px] font-bold my-4 text-center cursor-pointer underline text-[#0645AD]"
                        >
                          {commonTranslations.signWithEmail}
                        </p>
                      </center>
                      <center>
                        <Button
                          onClick={() => navigateTo("")}
                          variant="contained"
                          endIcon={<Home fontSize="small" />}
                        >
                          Go to home
                        </Button>
                      </center>
                    </div>
                  </Grid>
                )}
              </form>
            </Box>
          </Container>
        ) : (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar className="mb-8" sx={{ m: 1, bgcolor: "secondary.main" }}>
                <MobileFriendly />
              </Avatar>
              <center>
                <Typography
                  paragraph
                  style={{ fontWeight: "600" }}
                  variant="h5"
                >
                  {commonTranslations.verification}
                </Typography>
              </center>

              <form onSubmit={handleSubmit(onLogin)}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Field
                      name="verificationPhone"
                      component={GroupField}
                      placeholder={"Enter code here"}
                      label={""}
                      type="number"
                      border="1px solid #D0D0D0"
                      brRadius="6px"
                      height={48}
                      maxLength={6}
                    />
                    <p
                      onClick={onResendCode}
                      className="text-[14px] font-bold my-4 text-center cursor-pointer underline text-[#0645AD]"
                    >
                      {commonTranslations.resendCode}
                    </p>
                  </Grid>
                  <div className="w-full my-4" />
                  <Grid item xs={12}>
                    <center>
                      {verifyLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          disabled={isLoading}
                          onClick={onverifyPhoneCode}
                        >
                          {commonTranslations.verify}
                        </Button>
                      )}
                    </center>
                  </Grid>
                  <br />
                  <center>
                    <Button
                      onClick={() => navigateTo("")}
                      variant="contained"
                      endIcon={<Home fontSize="small" />}
                    >
                      Go to home
                    </Button>
                  </center>
                </Grid>
              </form>
            </Box>
          </Container>
        )}
      </div>
    );
  }
);
