import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import AddPostPage from './AddPostPage'
import validate from './validate'

const mapStateToProps = ({ form, user }) => ({
    form: form,
    isLoading: user.isLoading,
  })
  
  const AddPostPageWrapper = reduxForm({
      form: 'postAddForm',
      destroyOnUnmount: true,
      enableReinitialize: true,
      forceUnregisterOnUnmount: true,
      validate
    })(connect(mapStateToProps, {})(AddPostPage))
    
export default AddPostPageWrapper