import { commonTranslations } from "../../translation/commonTranslations";

const validate = (values) => {
  const { title, gender, condition, price, discount } = values;
  const errors = {};
  if (!title || title?.trim() === "") {
    errors.title = commonTranslations.required;
  }
  if (!gender || gender?.trim() === "") {
    errors.gender = commonTranslations.required;
  }
  if (!condition || condition?.trim() === "") {
    errors.condition = commonTranslations.required;
  }
  if (!price || price?.trim() === "") {
    errors.price = commonTranslations.required;
  }
  if (discount && discount?.trim() >= price?.trim()) {
    errors.discount = commonTranslations.priceIsMax;
  }
  return { ...errors };
};

export default validate;
