import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppTabs } from '../components/AppTabs/AppTabs'
import CookieBanner from '../components/CookieBanner/CookieBanner'
import Header from '../components/Header/Header'
import './syles.scss'

export const AppLayout = ({ back, noHeader, ...props}) => {
  const [isCookie, setIsCookie] = useState(window.localStorage.getItem('isCookieAccepted') || false)
  const history = useHistory()
  const isCookieAccepted = window.localStorage.getItem('isCookieAccepted') || false
  const navigateTo = (path, params =  {}) => {
    const country = window.localStorage.getItem('country')
    const language = window.localStorage.getItem('language')
    history.push(`/${country}/${language}/${path}`, params)
  }

  const acceptCookie = () => { 
    window.localStorage.setItem('isCookieAccepted', true)
    setIsCookie(true)
  }

  useEffect(() => {
    setIsCookie(isCookieAccepted)
  }, [isCookieAccepted])

  return (
    <div style={{ minHeight: window.innerHeight }} className='w-full flex flex-1 flex-col overflow-hidden'>
      {!noHeader && <Header back={back} />}
        <div className='w-full flex flex-1 flex-col z-0 overflow-y-auto pb-24'>
        {props.children}
        </div>
        <div className='w-full z-10 fixed bottom-0 bg-white tabBarOfBottom'>
          {}
      {!isCookie && <CookieBanner acceptCookie={acceptCookie} navigateTo={navigateTo} />}
      {
        !back && <AppTabs selected={props.selected} navigateTo={navigateTo} />
      }
        </div>
    </div>
  )
}
