import { db } from "../../../utilities/firebase";
import * as fireStore from "firebase/firestore";
import { showNotification } from "../../../components/snackbar/saga";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { getUserDetails } from "../../PostDetailPage/saga/saga";
import { v4 } from "uuid";
import { chatConstants } from "../../../utilities/chatUtils";

export const updateConversationLastMessage = ({ chatId, lastMessage, lastMessageId }) => {
  fireStore.updateDoc(doc(db, "conversation", chatId), {
    lastMessage,
    updatedAt: new Date(),
    lastMessageId
  });
};

export const updateMessageStatus = ({ chatId, recieverId }) => {
  fireStore
    .getDocs(
      query(
        collection(db, "messages"),
        where("conversationId", "==", chatId),
        where("recieverId", "==", recieverId)
      )
    )
    .then((res) => {
      res.forEach((item) => {
        fireStore.updateDoc(doc(db, "messages", item?.id), { status: "seen" });
      });
    });
};

export const fetchRealTimeChatMessages = ({ chatId, setMessage }) => {
  onSnapshot(
    query(
      collection(db, "messages"),
      where("conversationId", "==", chatId),
      orderBy("createdAt", "asc")
    ),
    (querySnapshot) => {
      querySnapshot.docChanges().forEach((item) => {
        if (item.type === "added") {
          setMessage({ ...item.doc.data(), id: item.doc.id });
        }
        if (item.type === "modified") {
          setMessage({ ...item.doc.data(), id: item.doc.id });
        }
      });
    }
  );
};

export const fetchRealTimeChatMessagesForIndication = ({ userId, setList }) => {
  onSnapshot(
    query(
      collection(db, "messages"),
      where("recieverId", "==", userId),
      where("status", "==", chatConstants.delivered)
    ),
    (querySnapshot) => {
      querySnapshot.docChanges().forEach((item) => {
        if (item.type === "added") {
          setList(item.doc.data())
        }
        if (item.type === "modified") {
          setList(item.doc.data())
        }
      });
    }
  );
};

export const fetchChatMessages = ({ page, docLimit, chatId }) =>
  new Promise(async (resolve, reject) => {
    if (page === 0) {
      const data = [];
      fireStore
        .getDocs(
          query(
            collection(db, "messages"),
            where("conversationId", "==", chatId),
            orderBy("createdAt", "desc"),
            limit(docLimit)
          )
        )
        .then((res) => {
          res.forEach((item) => {
            data.push({ ...item.data(), id: item.id });
          });
        })
        .then(() => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
      return;
    }
    const data = [];
    const first = query(
      collection(db, "messages"),
      orderBy("createdAt", "desc"),
      where("conversationId", "==", chatId),
      limit(page * docLimit + 1)
    );
    const documentSnapshots = await getDocs(first);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    fireStore
      .getDocs(
        query(
          fireStore.collection(db, "messages"),
          where("conversationId", "==", chatId),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(docLimit)
        )
      )
      .then((res) => {
        res.forEach((item) => {
          data.push({ ...item.data(), id: item.id });
        });
      })
      .then(() => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });

export const createMessageInChat = ({
  chatId,
  type,
  value,
  senderId,
  recieverId
}) =>
  new Promise((resolve, reject) => {
    const data = {
      conversationId: chatId,
      type,
      value,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: "delivered",
      senderId,
      recieverId,
    };
    const messageId = v4()
    fireStore
      .setDoc(doc(db, "messages", messageId), data)
      .then(() => {
        resolve();
        updateConversationLastMessage({ chatId, lastMessage: value, lastMessageId: messageId });
      })
      .catch((e) => {
        showNotification({ isError: true, message: e.message });
        reject(e);
      });
  });

export const fetchRealTimeConversation = async ({ userId, conversations, setConversations, selectedConversationId }) => {
  onSnapshot(
    query(
      collection(db, "messages"),
      where("recieverId", "==", userId)
    ),
    (querySnapshot) => {
      querySnapshot.docChanges().forEach((item) => {
        if (item.type === "added" && item.doc.data().status === chatConstants.delivered) {
          const convs = [...conversations]
          const index = convs.findIndex(data => data.id === item.doc.data().conversationId)
          if (index > -1 && item.doc.data().conversationId !== selectedConversationId) {
            convs[index] = {...convs[index], unreadCount: 1, lastMessage:item.doc.data().value }
            setConversations(convs)
          }
        }
        if (item.type === "added" && item.doc.data().status === chatConstants.seen) {
          const convs = [...conversations]
          const index = convs.findIndex(data => data.id === item.doc.data().conversationId)
          if (index > -1 && item.doc.data().conversationId === selectedConversationId) {
            convs[index] = {...convs[index], unreadCount: 0, lastMessage:item.doc.data().value }
            setConversations(convs)
          }
        }
      });
    }
  );
};

export const fetchSingleConversation = async ({ chatId, userId }) => {
  const chat = await fireStore.getDoc(
    fireStore.doc(db, "conversation", chatId)
  );
  const otherUser = chat.data()?.users.filter((user) => user !== userId)[0];
  const chatUser = await getUserDetails({
    userId: otherUser,
  });
  return Promise.resolve({ ...chat.data(), id: chat?.id, chatUser });
};

export const fetchUnreadCountOfConversation = async ({ chatId, otherData = {}, userId }) => {
  const response = await fireStore
    .getDocs(
      query(
        collection(db, "messages"),
        where("conversationId", "==", chatId),
        where("recieverId", "==", userId),
        where("status", "==", chatConstants.delivered)
      )
    )
    return Promise.resolve({size: response.size, otherData});
};

export const fetchRealTimeUserConversationList = ({ userId, setConversation }) => {
  onSnapshot(
    query(
      collection(db, "conversation"),
      where("users", "array-contains", userId),
    ),
    (querySnapshot) => {
      querySnapshot.docChanges().forEach((item) => {
        if (item.type === "added") {
          fetchSingleConversation({ chatId: item.doc.id, userId }).then((snapshot) => {
            setConversation({ ...snapshot, unreadCount: 1 });
          })
        }
      });
    }
  );
};

export const fetchUserConversations = ({ page, docLimit, userId }) =>
  new Promise(async (resolve, reject) => {
    if (page === 0) {
      const data = [];
      const promises = [];
      const nextPromise = []
      fireStore
        .getDocs(
          query(
            collection(db, "conversation"),
            where("users", "array-contains", userId),
            orderBy("updatedAt", "desc"),
            limit(docLimit)
          )
        )
        .then((res) => {
          res.forEach(async (item) => {
            const otherUser = item
              .data()
              ?.users.filter((user) => user !== userId)[0];
            promises.push(
              getUserDetails({
                userId: otherUser,
                otherData: { ...item.data(), id: item.id },
              })
            );
          });
        })
        .then(() => {
          Promise.all(promises).then((promisedData) => {
            promisedData.forEach((snapShot, index) => {
              const mergedData = { ...snapShot?.otherData, chatUser: snapShot }
              nextPromise.push(fetchUnreadCountOfConversation({ otherData: mergedData, chatId: snapShot?.otherData?.id, userId }))
            });
          }).then(() => {
            Promise.all(nextPromise).then(promisedData => {
              promisedData.forEach((snapShot) => {
              const mergedData = { ...snapShot?.otherData, unreadCount: snapShot.size || 0 }
              data.push(mergedData);       
            })
            }).then(() => {
              resolve(data);
            })
          })
        })
        .catch((e) => {
          reject(e);
        });
      return;
    }
    const data = [];
    const promises = [];
    const first = query(
      collection(db, "conversation"),
      where("users", "array-contains", userId),
      orderBy("updatedAt", "desc"),
      limit(page * docLimit + 1)
    );
    const documentSnapshots = await getDocs(first);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    fireStore
      .getDocs(
        query(
          fireStore.collection(db, "conversation"),
          where("users", "array-contains", userId),
          orderBy("updatedAt", "desc"),
          startAfter(lastVisible),
          limit(docLimit)
        )
      )
      .then((res) => {
        res.forEach(async (item) => {
          const otherUser = item
            .data()
            ?.users.filter((user) => user !== userId)[0];
          promises.push(
            getUserDetails({
              userId: otherUser,
              otherData: { ...item.data(), id: item.id },
            })
          );
        });
      })
      .then(() => {
        Promise.all(promises).then((promisedData) => {
          promisedData.forEach((snapShot, index) => {
            data.push({ ...snapShot?.otherData, chatUser: snapShot });
          });
          resolve(data);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
