import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import TikTokIcon from "../../assets/images/tiktok.png";
import { Facebook, Instagram, Twitter, Phone, Mail } from "@mui/icons-material";
import { AppLayout } from "../../Layout/AppLayout";
import { fetchUserPosts } from "../MyPostsPage/sagas/saga";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import PostHomeCard from "../../components/PostHomeCard/PostHomeCard";
import { getUserDetails } from "../PostDetailPage/saga/saga";
import { userPlaceholderImage } from "../../utilities/random";
import { commonTranslations } from "../../translation/commonTranslations";

export const OtherProfilePage = () => {
  const history = useHistory();
  const { country = "", language = "" } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({})
  const { userId } = useParams()
  const navTo = (path, params = {}) => `/${country}/${language}/${path}`;
  const [userPosts, setUserPosts] = useState([]);
  const isSocial =
    user?.facebook || user?.instagram || user?.twitter || user?.tiktok;
  const onSocialClick = (url) => {
    window.open(url, "_blank");
  };

  const onPhoneClick = (phone) => {
    window.open(`tel:${phone}`);
  };

  const onEmailClick = (email) => {
    window.open(`mailto:${email}`);
  };

  const fetchPosts = () => {
    const data = [];
    fetchUserPosts({ userId: user?.id, limit: 3 })
      .then((res) => {
        res?.forEach((item) => {
          data.push({ ...item.data(), id: item.id });
        });
        setIsLoading(false);
      })
      .then(() => {
        setUserPosts(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!user?.id) return
    fetchPosts();
  }, [user]);

  useEffect(() => {
    setIsLoading(true)
    getUserDetails({ userId }).then((res) => {
        setIsLoading(false)
        setUser(res)
    }).catch(e => {
        setIsLoading(false)
    })
  }, []);

  if (isLoading) {
    return <FullPageLoading />;
  }

  return (
    <AppLayout back>
      <div className="w-full items-center justify-center flex">
        <div className="p-3 max-w-[700px] w-full">
          <div className="flex align-items-center justify-center flex-col mt-3">
            <img
              className="w-[150px] h-[150px] rounded-full object-cover cursor-pointer"
              src={user?.userImage || userPlaceholderImage}
              alt={"profile"}
            />
            <p className="text-[22px] font-bold mt-3">{user?.name}</p>

            {isSocial && (
              <div className="flex gap-4 mt-3">
                {user?.facebook && (
                  <Facebook
                    onClick={() => onSocialClick(user?.facebook)}
                    className="text-blue-800 cursor-pointer"
                  />
                )}
                {user?.instagram && (
                  <Instagram
                    onClick={() => onSocialClick(user?.instagram)}
                    className="text-[#F77737] cursor-pointer"
                  />
                )}
                {user?.twitter && (
                  <Twitter
                    onClick={() => onSocialClick(user?.twitter)}
                    className="text-[#1DA1F2] cursor-pointer"
                  />
                )}
                {user?.tiktok && (
                  <img
                    onClick={() => onSocialClick(user?.tiktok)}
                    src={TikTokIcon}
                    className="w-[20px] h-[20px] cursor-pointer"
                    alt={"tiktok"}
                  />
                )}
              </div>
            )}

            <div className="mt-3 w-full">
              <p className="text-[22px] font-bold underline">About</p>
              {user?.description && (
                <p className="font-semibold mt-1 text-[16px]">
                  {user?.description}
                </p>
              )}
              <div className="flex gap-2 mt-3">
                <div className="flex gap-1">
                  <Phone color="secondary" />:
                </div>
                <p
                  onClick={() => onPhoneClick(user?.phone)}
                  className="underline cursor-pointer"
                >
                  {user?.phone}
                </p>
              </div>
              {user?.email && (
                <div className="flex gap-2 mt-3">
                  <div className="flex gap-1">
                    <Mail color="secondary" />:
                  </div>
                  <p
                    onClick={() => onEmailClick(user?.email)}
                    className="underline cursor-pointer"
                  >
                    {user?.email}
                  </p>
                </div>
              )}
            </div>

            <div className="mt-4 w-full">
            <div className="flex items-center justify-between">
              <p className="text-[22px] font-bold underline">Posts</p>
              <p onClick={() => history.push(navTo(`posts/${userId}`))} className='text-[16px] cursor-pointer text-gray-600'>{commonTranslations.viewAll}</p>
              </div>
              {userPosts.length > 0 ? (
                <div className="flex w-full overflow-x-auto gap-4 scroll-bar-style pb-4">
                  {userPosts?.map((item, i) => (
                    <Link to={navTo(`post/${item?.id}`)}>
                      <PostHomeCard
                        description={item?.description}
                        id={item.id}
                        image={item?.images?.[0]}
                        key={i}
                        title={item?.title}
                        condition={item?.condition}
                      />
                    </Link>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 mt-2 italic">No posts to show</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
