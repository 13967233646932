import { doc, deleteDoc  } from "firebase/firestore";
import { db } from "../../../utilities/firebase";
import { showNotification } from "../../snackbar/saga";

export const onDeletePost = (id) => new Promise((resolve, reject) => {
    deleteDoc(doc(db, "posts", id)).then(res => {
        resolve()
        showNotification({ message: 'Success!' });
    }).catch(e => {
        reject()
        showNotification({ isError: true, message: e.message });
    })
})