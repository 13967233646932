import { LoginPage } from './LoginPage'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import validate from './validate'

const mapStateToProps = ({ form, user }) => ({
  form: form,
  isLoading: user.isLoading
})

const LoginWrapper = reduxForm({
    form: 'signinForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    validate
  })(connect(mapStateToProps, {})(LoginPage))
  
  export default LoginWrapper