import { ProfilePageEdit } from './ProfilePageEdit'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import validate from './validate'

const mapStateToProps = ({ form, user }) => ({
  form: form
})

const ProfilePageEditWrapper = reduxForm({
    form: 'profileForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    validate
  })(connect(mapStateToProps, {})(ProfilePageEdit))
  
  export default ProfilePageEditWrapper