import { Grid, MenuItem, Select } from '@material-ui/core'
import Label from '../Label/Label'
import Error from '../error/error'
import '../Input/Input.css'

export default function GroupDropDown (props) {
  const { label, required, menus = [], height } = props
  const { error, submitFailed } = props.meta

  return (
    <>
      <Grid container spacing={1} item direction='column'>
        {label && (
          <Grid item>
            <Label title={label} required={!!required || false} />
          </Grid>
        )}
        <Grid item>
        <div className={"custom-input-wrapper"}>
        <Select
          {...props.input}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          variant='standard'
          className='w-full'
          style={{ height }}
        >
            {menus.map((item, i) => <MenuItem key={i} value={item.value}>{item.name}</MenuItem> )}
        </Select>
        </div>
        </Grid>
      </Grid>
      {submitFailed && error && <Error error={error} />}
    </>
  )
}
