import { NativeSelect } from "@material-ui/core";
import React from "react";
import { currencies } from "../../utilities/currencies";
import "./Input.css";

const Input = (props) => {
  const filteredCurency = currencies.filter((value, index, self) =>
  index === self.findIndex((t) => (
    t.currencyCode === value.currencyCode
  ))
).sort((a,b) => (a.currencyCode > b.currencyCode) ? 1 : ((b.currencyCode > a.currencyCode) ? -1 : 0))
  const { bgColor, border, fontSize, placeholder, brRadius, height, multline, rows = 8, isPrice = false } =
    props;
  const styleSheet = {
    backgroundColor: bgColor || "white",
    border: border || "1px solid gray",
    fontSize: fontSize || "",
    borderRadius: brRadius || "",
    height: height || "initial",
    ...(multline && { alignItems: "flex-start", justifyContent: "flex-start",paddingTop: '1rem' }),
  };
  if (isPrice) {
    return (
      <div className="input-group mb-3">
        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{props?.currencyCode}</button>
        <ul className="dropdown-menu max-h-[200px] overflow-y-auto">
        {filteredCurency.map(item => <li onClick={() => props.setCurrencyCode(item?.currencyCode)} value={item.currencyCode} className="dropdown-item cursor-pointer">{item?.currencyCode}</li>)}
        </ul>
        <input
         className="form-control" aria-label="Text input with dropdown button"  
          {...props.input}
          type={props.type}
          placeholder={placeholder || ""}
          disabled={props.disabled}
          />
      </div>
    )
  }

  return (
    <div style={styleSheet} className={"input-wrapper"}>
      {multline ? (
        <textarea
          {...props.input}
          placeholder={placeholder || ""}
          style={{ width: "100%", resize: "none" }}
          rows={rows}
        />
      ) : isPrice ?
      (
        <div className="flex items-center justify-center">
          <div className="pl-2 w-[100px]">
              <NativeSelect color={undefined} onChange={props.setCurrencyCode} value={props?.currencyCode} IconComponent={() => <div className="w-0" />} id="select">
                {filteredCurency.map(item => <option value={item.currencyCode}>{item.currencyCode}</option>)}
              </NativeSelect>
              </div>
           <input
          {...props.input}
          type={props.type}
          placeholder={placeholder || ""}
          multiple
        />
          </div>         
      )
      : (
        <input
          {...props.input}
          type={props.type}
          placeholder={placeholder || ""}
          multiple
          disabled={props.disabled}
          className={props.className}
        />
      )}
    </div>
  );
};
export default Input;
