import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import PostCard from '../../components/PostCard/PostCard'
import { AppLayout } from '../../Layout/AppLayout'
import { fetchUserPosts } from './sagas/saga'
import { Add } from '@mui/icons-material'

export default function MyPostsPage() {
  const { country = '', language = '' } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const history = useHistory()
  const user = useSelector(state => state.user.user)
  const navTo = (path, params =  {}) => `/${country}/${language}/${path}`

  const fetchPosts = () => {
    const data = []
    fetchUserPosts({ userId: user?.id }).then(res => {
      res?.forEach(item => {
        data.push({ ...item.data(), id: item.id })
      })
      setIsLoading(false)
    }).then(() => {
      setList(data)
    }).catch(() => {
      setIsLoading(false)
    })
  }
  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <AppLayout selected={1}>
       <Helmet>
        <title>My posts | dresez.com</title>
      </Helmet>
      <div className='w-full flex items-center flex-col'>
        <div className='w-full max-w-[1400px] p-2'>
          <p className='text-[22px] text-black font-bold text-left'>My Posts</p>

          <div className='w-full mt-4'>
            {
              isLoading ?
                <div className='w-full flex flex-col items-center mt-4'>
                  <CircularProgress color='primary' />
                </div>
                : list.length > 0 ? list.map((item, i) => <PostCard onClick={() => history.push(navTo(`post/${item?.id}`))} description={item?.description} fetchPosts={fetchPosts} id={item.id} image={item?.images?.[0]} key={i} title={item?.title} condition={item?.condition} />) :
                <Link to={navTo('add-post')}>
                  <div className='flex flex-col items-start gap-2 mt-4'>
                  You don't have any posts
                   <div style={{ border: `1px solid rgb(131,56,236)` }} className="text-[#8338EC] font-bold cursor-pointer rounded p-2 flex items-center w-max">
                      <Add /> &nbsp;
                      Create one
                  </div>
                  </div>
                  </Link>
            }
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
