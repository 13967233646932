import { commonTranslations } from '../../translation/commonTranslations'
import isEmail from 'validator/lib/isEmail'

const validate = (values) => {
  const {
    fullName,
    contactNumber,
    email,
    password
  } = values
  const errors = {}
  if (!password || password.trim() === '') {
    errors.password = commonTranslations.required
  }
  if (contactNumber) {
    const regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
    if (!regExp.test(contactNumber)) {
      errors.contactNumber = commonTranslations.invalidPhone
    }
  }
  if (!email || email.trim() === '') {
    errors.email = commonTranslations.required
  }
  if (email && !isEmail(email)) {
    errors.email = commonTranslations.enterValidEmail
  }
  return { ...errors }
}

export default validate
