import { Autocomplete, Box, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/ArrowRightAlt";
import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading/Heading";
import { countries } from "../../utilities/countries";
import { useHistory } from "react-router-dom";
import { setCountry } from "./sagas/saga";
import { useSelector } from "react-redux";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import ErrorBoundary from "../../components/ErrorPage/errorBoundary";

function Countrypage() {
  const history = useHistory();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [fetchloading, setFetchloading] = useState(false)
  const { isLoading, isError, error } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    setFetchloading(true)
    const country = window.localStorage.getItem('country')

    if (country) {
      history.push(`/${country}`)
      window.localStorage.setItem('country', country)
      return;
    }
    fetch('https://api.ipregistry.co/?key=tryout')
    .then(function (response) {
        return response.json();
    })
    .then(function (payload) {
      setFetchloading(false)
      setCountry(payload.location.country.code?.toLowerCase())
      window.localStorage.setItem('country',payload.location.country.code?.toLowerCase())
      history.push(`/${payload.location.country.code?.toLowerCase()}`)
    }).catch(() => {
      setFetchloading(false)
    })
  }, []);

  const goToLanguageSelection = (en) => {
    history.push(`/${en ? "us" : selectedCountry}`);
    setCountry(selectedCountry)
    window.localStorage.setItem('country',selectedCountry)
  }

  if (fetchloading || isLoading) {
    return <FullPageLoading />;
  }

  if (isError) {
    return <ErrorBoundary message={error} />;
  }

  return (
    <div
      style={{ height: window.innerHeight }}
      className="w-full items-center justify-center flex flex-col"
    >
      <div className="w-[90%] md:w-[80%]">
        <Heading>Choose a country</Heading>
        <br />
        <Autocomplete
          className="w-full"
          id="country-select-demo"
          options={countries}
          autoHighlight
          onChange={(e, val) => setSelectedCountry(val.code.toLowerCase())}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={"select your country here"}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
            />
          )}
        />
        <div className="w-full flex flex-col items-center justify-center mt-10">
          <Button
            onClick={goToLanguageSelection}
            variant="contained"
            endIcon={<SendIcon />}
            disabled={!selectedCountry}
          >
            <p className="font-bold">Continue</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Countrypage;
