import { Button, Modal } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { commonTranslations } from '../../translation/commonTranslations'

export const DeleteModal = ({ visible, onCLose, isLoading, onDelete, image, name, heading }) => {
  return (
    <Modal className='flex flex-1 items-center justify-center' open={visible} onClose={onCLose}>
        <div className='max-w-[800px] bg-white rounded w-[95%] p-4'>
          <p className='text-[20px] font-bold'>{ heading || "Are u sure u want to delete this item ?" }</p>
          <div className='w-full flex flex-1 items-center justify-center flex-col mt-2'>
            {image && <img src={image} className='w-[250px] h-[250px] object-contain bg-gray-100' />}
            {name && <p className='text-[18px] mt-2'>{name}</p>}
          </div>
        <div className='w-full mt-4 flex items-center justify-between'>
        <LoadingButton
          variant='outlined'
          color='secondary'
          loading={isLoading}
          onClick={onDelete}
        >
          {commonTranslations.postDelete}
        </LoadingButton>
        <Button
          variant="contained"
          color="primary"
          onClick={onCLose}
        >
          {commonTranslations.postDontDelete}
        </Button>
        </div>
        </div>
    </Modal>
  )
}
