import { connect } from 'react-redux'
import Snackbar from './snackbar'
import { NOTIFIER_HIDE } from './types'

const mapStateToProps = ({ notifierReducer }) => ({
  showNotification: notifierReducer.showNotification,
  notification: notifierReducer.notification
})

const mapDispatchToProps = (dispatch) => ({
  closeNotifier: () => {
    dispatch({ type: NOTIFIER_HIDE })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)
