import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import AddPostPage from "./containers/AddPostPage";
import Countrypage from "./containers/CountryPage";
import ProfilePageEdit from "./containers/ProfilePageEdit";
import HomePage from "./containers/HomePage";
import Languagepage from "./containers/LanguagePage/LanguagePage";
import LoginPage from "./containers/LoginPage";
import MyPostsPage from "./containers/MyPostsPage/MyPostsPage";
import PostDetailPage from "./containers/PostDetailPage/PostDetailPage";
import PrivacyPage from "./containers/PrivacyPage/PrivacyPage";
import SettingsPage from "./containers/SettingsPage/SettingsPage";
import SignupPage from "./containers/SignupPage";
import {
  onUserVote,
  useFirebaseUser,
} from "./containers/SignupPage/sagas/saga";
import TermsPage from "./containers/TermsPage/TermsPage";
import ViewAllPosts from "./containers/ViewAllPosts/ViewAllPosts";
import { commonTranslations } from "./translation/commonTranslations";
import { countries } from "./utilities/countries";
import { ProfilePage } from "./containers/ProfilePage/ProfilePage";
import { OtherProfilePage } from "./containers/ProfilePage/OtherProfilePage";
import { OthersPostsPage } from "./containers/MyPostsPage/OthersPostPage";
import { ChatPage } from "./containers/ChatPage/ChatPage";

export default function Routes() {
  const { user } = useSelector((state) => state.user);
  const { path } = useSelector((state) => state.auth);
  const RedirectToHome = () => (
    <Redirect to={`${path?.country}/${path?.language}`} />
  );

  return (
    <Router>
      <Route>
        <Switch>
          <Route exact path={"/"} component={Countrypage} />
          <Route exact path={"/:country"} component={Languagepage} />
          <Route
            exact
            path={"/:country/:language"}
            component={() => (
              <CountryCheck>
                <HomePage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/post/:postId"}
            component={() => (
              <CountryCheck>
                <PostDetailPage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/settings"}
            component={() => (
              <CountryCheck>
                <SettingsPage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/privacy"}
            component={() => (
              <CountryCheck>
                <PrivacyPage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/terms"}
            component={() => (
              <CountryCheck>
                <TermsPage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/user/:userId"}
            component={() => (
              <CountryCheck>
                <OtherProfilePage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/latest"}
            component={() => (
              <CountryCheck>
                <ViewAllPosts />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/edit/:postId"}
            component={() => (
              <PrivateRoute>
                <CountryCheck>
                  <AddPostPage isEdit />
                </CountryCheck>
              </PrivateRoute>
            )}
          />
          <Route
            exact
            path={"/:country/:language/add-post"}
            component={() => (
              <PrivateRoute>
                <CountryCheck>
                  <AddPostPage />
                </CountryCheck>
              </PrivateRoute>
            )}
          />
          <Route
            exact
            path={"/:country/:language/posts"}
            component={() => (
              <PrivateRoute>
                <CountryCheck>
                  <MyPostsPage />
                </CountryCheck>
              </PrivateRoute>
            )}
          />
          <Route
            exact
            path={"/:country/:language/posts/:userId"}
            component={() => (
                <CountryCheck>
                  <OthersPostsPage />
                </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/profile"}
            component={() => (
              <PrivateRoute>
                <CountryCheck>
                  <ProfilePage />
                </CountryCheck>
              </PrivateRoute>
            )}
          />
          <Route
            exact
            path={"/:country/:language/profile/edit"}
            component={() => (
              <PrivateRoute>
                <CountryCheck>
                  <ProfilePageEdit />
                </CountryCheck>
              </PrivateRoute>
            )}
          />
          <Route
            exact
            path={"/:country/:language/chats"}
            component={() => (
              <CountryCheck>
                <ChatPage />
              </CountryCheck>
            )}
          />
          <Route
            exact
            path={"/:country/:language/chats/:chatId"}
            component={() => (
              <CountryCheck>
                <ChatPage />
              </CountryCheck>
            )}
          />
          {!user?.id && (
            <>
              <Route
                exact
                path={"/:country/:language/signup"}
                component={SignupPage}
              />
              <Route
                exact
                path={"/:country/:language/signin/email"}
                component={LoginPage}
              />
              <Route
                exact
                path={"/:country/:language/signin/email/reset"}
                component={(props) => <LoginPage {...props} emailReset />}
              />
              <Route
                exact
                path={"/:country/:language/signin"}
                component={(props) => <LoginPage {...props} phone />}
              />
            </>
          )}
          <Route exact component={RedirectToHome} />
        </Switch>
      </Route>
    </Router>
  );
}

const PrivateRoute = (props) => {
  const history = useHistory();
  const language = window.localStorage.getItem("language") || "en";
  const country = window.localStorage.getItem("country") || "us";
  const { user, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user?.id && !isLoading) {
      history.push(`/${country}/${language}/signin`);
    }
  }, []);

  if (user?.id) {
    return props.children;
  }
};

const CountryCheck = (props) => {
  const { user } = useFirebaseUser();
  const { country = "" } = useParams();
  const [countryNotAllowed, setCountryNotAllowed] = useState(false);

  useEffect(() => {
    if (
      !countries.find(
        (item) => item.code.toLowerCase() === country.toLowerCase()
      )?.isAllowed
    ) {
      setCountryNotAllowed(true);
      return;
    }
  }, [country]);

  if (countryNotAllowed) {
    return (
      <div
        style={{ height: window.innerHeight }}
        className="w-full flex items-center flex-col justify-center text-[18px] px-4"
      >
        {commonTranslations.notAllowedCountry}

        <div className="mt-4">
          <Button
            onClick={() => onUserVote({ userData: user, country })}
            type="button"
            color="primary"
            variant="outlined"
          >
            <p className="font-bold">{commonTranslations.vote}</p>
          </Button>
        </div>
      </div>
    );
  }

  return props.children;
};
