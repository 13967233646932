import {
  signInWithEmailAndPassword,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail
} from "firebase/auth";
import * as fireStore from 'firebase/firestore'
import { getDoc, doc, setDoc } from "firebase/firestore";
import { showNotification } from "../../../components/snackbar/saga";
import store from "../../../store";
import { commonTranslations } from "../../../translation/commonTranslations";
import { db } from "../../../utilities/firebase";
import { getUser } from "../../SignupPage/sagas/saga";
import {
  GET_USER_FAILED,
  GET_USER_LOADING,
} from "../../SignupPage/sagas/types";

export const resetEmail = ({ email, push }) => {
  sendPasswordResetEmail(getAuth(), email, { url: 'https://dresez.com/' })
  .then((link) => {
    showNotification({ message: 'Link has been sent to email' });
    push()
  })
  .catch((e) => {
    showNotification({ isError: true, message: e.message });
  });
}

export const loginUser = ({ email, pass }) => {
  store.dispatch({ type: GET_USER_LOADING });
  signInWithEmailAndPassword(getAuth(), email, pass)
    .then((res) => {
      window.localStorage.setItem("userId", res.user.uid);
      getUser({ userId: res.user.uid });
    })
    .catch((e) => {
      store.dispatch({ type: GET_USER_FAILED });
      showNotification({ isError: true, message: e.code === 'auth/user-not-found' ? 'User does not exist' : e.code === 'auth/wrong-password' ? 'Password does not match' : e.message });
    });
};

export const loginWithPhone = ({ phone }) =>
  new Promise((resolve, reject) => {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        resolve();
        window.confirmationResult = confirmationResult;
      })
      .catch((e) => {
        reject();
        showNotification({ isError: true, message: e.message });
      });
  });

  export const verifyPhoneNumber = ({ code, phone }) => new Promise((resolve, reject) => {
    fireStore.getDocs(fireStore
      .query(fireStore.collection(db, "users"), fireStore.where("contactNumber", "==", phone)))
  .then((res) => {
    if (res.size > 0) {
      window.confirmationResult.confirm(code).then(result => {
        const user = result.user;
        getDoc(doc(db, "users", user?.uid))
        .then((res) => {
          const isExist = res.exists()
  
          if (isExist && !res.data().isAnonymousUser) {
            getUser({ userId: user.uid });
            return
          }
          setDoc(doc(db, "users", user.uid), { email: '', pass: '', name: '', phone: user?.phoneNumber, id: user.uid, contactNumber:  user?.phoneNumber })
          .then((data) => {
            getUser({ userId: user.uid });
            showNotification({ message: "Success!" });
          })
          .catch((e) => {
            showNotification({ isError: true, message: e.message });
          });
        })
        .catch((e) => {
          showNotification({ isError: true, message: e.message });
        });
      }).catch((e) => {
        reject()
        showNotification({ isError: true, message: e.message });
      })
      return
    }

    const credential = PhoneAuthProvider.credential(window.confirmationResult.verificationId, code)
    linkWithCredential(getAuth().currentUser, credential).then(result => {
      const user = result.user;
      getDoc(doc(db, "users", user?.uid))
      .then((res) => {
        const isExist = res.exists()

        if (isExist && !res.data().isAnonymousUser) {
          getUser({ userId: user.uid });
          return
        }
        setDoc(doc(db, "users", user.uid), { email: '', pass: '', name: '', phone: user?.phoneNumber, id: user.uid, contactNumber:  user?.phoneNumber })
        .then((data) => {
          getUser({ userId: user.uid });
          showNotification({ message: "Success!" });
        })
        .catch((e) => {
          showNotification({ isError: true, message: e.message });
        });
      })
      .catch((e) => {
        showNotification({ isError: true, message: e.message });
      });
    }).catch((e) => {
      reject()
      showNotification({ isError: true, message: e.message });
    })

  })
  })

  export const resendCode = ({ phone }) => {
    const auth = getAuth();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier).then(() => {
      showNotification({ message: commonTranslations.codeHasBeenResend });
    })
  }