import { db } from "../../../utilities/firebase";
import * as fireStore from "firebase/firestore";
import { collection, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";

export const fetchLatestPosts = ({ userCountry }) =>
  new Promise((resolve, reject) => {
    fireStore.getDocs(query(fireStore.collection(db, "posts"), fireStore.where("country", "==", userCountry), fireStore.where("status", "==", "APPROVED"), orderBy("createdAt", "asc"), limit(10)))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
      });
  });

export const fetchLatestPostsWithPagination = ({ page, docLimit, userCountry }) =>
  new Promise(async (resolve, reject) => {
    const first = query(collection(db, "posts"), orderBy("createdAt", "asc"), limit((page * docLimit) + 1), fireStore.where("status", "==", "APPROVED"));
const documentSnapshots = await getDocs(first);

// Get the last visible document
const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    fireStore.getDocs(query(fireStore.collection(db, "posts"), fireStore.where("country", "==", userCountry), fireStore.where("status", "==", "APPROVED") ,orderBy("createdAt", "asc"), startAfter(lastVisible) , limit(docLimit)))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
      });
  });

export const fetchPrelovedPosts = ({ userCountry }) =>
  new Promise((resolve, reject) => {
    
    fireStore.getDocs(query(fireStore.collection(db, "posts"), fireStore.where("country", "==", userCountry) ,fireStore.where("condition", "==", "pre-loved"), fireStore.where("status", "==", "APPROVED")))
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject();
      });
  });