import React from "react";
import { FormattedMessage } from "react-intl";

export const commonTranslations = {
  cookieText: (
    <FormattedMessage
      id="cookieText"
      description="dresez.com uses cookies and browser storage to save data related to user and its preferences. Also it collects data related to user experience for improving the performance and to deliver the best possible value. By tapping on accept you agree to dresez.com {terms}. See {privacy}."
      defaultMessage="dresez.com uses cookies and browser storage to save data related to user and its preferences. Also it collects data related to user experience for improving the performance and to deliver the best possible value. By tapping on accept you agree to dresez.com {terms}. See {privacy}."
    />
  ),
  privacyPolicy: (
    <FormattedMessage
      id="privacyPolicy"
      description="Privacy Policy"
      defaultMessage="Privacy Policy"
    />
  ),
  termsAndConditions: (
    <FormattedMessage
      id="termsAndConditions"
      description="Terms and Conditions"
      defaultMessage="Terms and Conditions"
    />
  ),
  votePlaced: (
    <FormattedMessage
      id="votePlaced"
      description="Vote Placed"
      defaultMessage="Vote Placed"
    />
  ),
  vote: (
    <FormattedMessage
      id="vote"
      description="vote"
      defaultMessage="vote"
    />
  ),
  notAllowedCountry: (
    <FormattedMessage
      id="notAllowedCountry"
      description="dresez isn't available in your country, vote for quick launch in your region"
      defaultMessage="dresez isn't available in your country, vote for quick launch in your region"
    />
  ),
  post: (
    <FormattedMessage
      id="post:post"
      description="Post"
      defaultMessage="Post"
    />
  ),
  nobodyPostedWhile: (
    <FormattedMessage
      id="nobodyPostedWhile"
      description="Nobody has posted since a while, would you like to "
      defaultMessage="Nobody has posted since a while, would you like to "
    />
  ),
  post: (
    <FormattedMessage
      id="post:post"
      description="Post"
      defaultMessage="Post"
    />
  ),
  nobodyPostedWhile: (
    <FormattedMessage
      id="nobodyPostedWhile"
      description="Nobody has posted since a while, would you like to "
      defaultMessage="Nobody has posted since a while, would you like to "
    />
  ),
  signWithEmail: (
    <FormattedMessage
      id="signWithEmail"
      description="Signin with email"
      defaultMessage="Signin with email"
    />
  ),
  signWithPhone: (
    <FormattedMessage
      id="signWithPhone"
      description="Signin with phone"
      defaultMessage="Signin with phone"
    />
  ),
  verify: (
    <FormattedMessage
      id="verify"
      description="Verify"
      defaultMessage="Verify"
    />
  ),
  verification: (
    <FormattedMessage
      id="verification"
      description="Verification"
      defaultMessage="Verification"
    />
  ),
  signinWithPhone: (
    <FormattedMessage
      id="signin-with-phone"
      description="Signin with phone"
      defaultMessage="Signin with phone"
    />
  ),
  alreadyHaveAccount: (
    <FormattedMessage
      id="Already-have-account"
      description="Already have an account ?"
      defaultMessage="Already have an account ?"
    />
  ),
  dontHaveAccount: (
    <FormattedMessage
      id="Dont-have-account"
      description="Don't have an account ?"
      defaultMessage="Don't have an account ?"
    />
  ),
  dontHaveAccount: (
    <FormattedMessage
      id="Dont-have-account"
      description="Don't have an account ?"
      defaultMessage="Don't have an account ?"
    />
  ),
  viewAll: (
    <FormattedMessage
      id="viewAll"
      description="View all"
      defaultMessage="View all"
    />
  ),
  preloved: (
    <FormattedMessage
      id="Preloved"
      description="Preloved"
      defaultMessage="Preloved"
    />
  ),
  latest: (
    <FormattedMessage
      id="Latest"
      description="Latest"
      defaultMessage="Latest"
    />
  ),
  postDontDelete: (
    <FormattedMessage
      id="post:post:dont-delete"
      description="No dont"
      defaultMessage="No dont"
    />
  ),
  postDelete: (
    <FormattedMessage
      id="post:post:delete"
      description="Yes delete"
      defaultMessage="Yes delete"
    />
  ),
  postNow: (
    <FormattedMessage
      id="post:post-now"
      description="post:post-now"
      defaultMessage="Post Now"
    />
  ),
  loading: (
    <FormattedMessage
      id="Loading"
      description="Loading"
      defaultMessage="Loading"
    />
  ),
  fullName: (
    <FormattedMessage
      id="address-collector:full-name"
      description="Full Name"
      defaultMessage="Full Name"
    />
  ),
  contact: (
    <FormattedMessage
      id="address-collector:contact-number"
      description="Contact Number"
      defaultMessage="Contact Number"
    />
  ),
  email: (
    <FormattedMessage
      id="address-collector:contact-email"
      description="Email"
      defaultMessage="Email"
    />
  ),
  password: (
    <FormattedMessage
      id="address-collector:password"
      description="Password"
      defaultMessage="Password"
    />
  ),
  signup: (
    <FormattedMessage
      id="address-collector:signup"
      description="Signup"
      defaultMessage="Signup"
    />
  ),
  signin: (
    <FormattedMessage
      id="address-collector:signin"
      description="Sign in"
      defaultMessage="Sign in"
    />
  ),
  condition: (
    <FormattedMessage
      id="post:condition"
      description="condition"
      defaultMessage="condition"
    />
  ),
  discountedPrice: (
    <FormattedMessage
      id="post:discountedPrice"
      description="Discounted price"
      defaultMessage="Discounted price"
    />
  ),
  price: (
    <FormattedMessage
      id="post:price"
      description="price"
      defaultMessage="price"
    />
  ),
  gender: (
    <FormattedMessage
      id="post:gender"
      description="gender"
      defaultMessage="gender"
    />
  ),
  brandName: (
    <FormattedMessage
      id="brandName"
      description="Brand Name"
      defaultMessage="Brand Name"
    />
  ),
  type: (
    <FormattedMessage
      id="post:type"
      description="type"
      defaultMessage="type"
    />
  ),
  tiktok: (
    <FormattedMessage
      id="tiktok"
      description="tiktok"
      defaultMessage="tiktok"
    />
  ),
  twitter: (
    <FormattedMessage
      id="twitter"
      description="twitter"
      defaultMessage="twitter"
    />
  ),
  instagram: (
    <FormattedMessage
      id="instagram"
      description="instagram"
      defaultMessage="instagram"
    />
  ),
  facebook: (
    <FormattedMessage
      id="facebook"
      description="facebook"
      defaultMessage="facebook"
    />
  ),
  description: (
    <FormattedMessage
      id="post:description"
      description="description"
      defaultMessage="description"
    />
  ),
  title: (
    <FormattedMessage
      id="post:title"
      description="title"
      defaultMessage="title"
    />
  ),
  required: (
    <FormattedMessage
      id="error.required"
      description="Required field"
      defaultMessage="Required field"
    />
  ),
  priceIsMax: (
    <FormattedMessage
      id="error.priceIsMax"
      description="Discounted price should be less than Actual Price"
      defaultMessage="Discounted price should be less than Actual Price"
    />
  ),
  invalidPhone: (
    <FormattedMessage
      id="error.invalidPhone"
      description="Invalid phone"
      defaultMessage="Invalid phone"
    />
  ),
  phoneNumberHere: (
    <FormattedMessage
      id="phoneNumberHere"
      description="Phone number here"
      defaultMessage="Phone number here"
    />
  ),
  codeHasBeenResend: (
    <FormattedMessage
      id="codeHasBeenResend"
      description="Code has been resend"
      defaultMessage="Code has been resend"
    />
  ),
  resendCode: (
    <FormattedMessage
      id="resend"
      description="resend code"
      defaultMessage="resend code"
    />
  ),
  addDescriptionHere: (
    <FormattedMessage
      id="addDescriptionHere"
      description="Add Description here"
      defaultMessage="Add Description here"
    />
  ),
  addTitleHere: (
    <FormattedMessage
      id="addTitleHere"
      description="Add title here"
      defaultMessage="Add title here"
    />
  ),
  enterValidEmail: (
    <FormattedMessage
      id="error.enterValidEmail"
      description="Please enter a valid email"
      defaultMessage="Please enter a valid email"
    />
  ),
}
