import React from 'react'
import { injectIntl } from "react-intl";
import { linkColor } from '../../utilities/random';

const CookieBanner = ({ navigateTo, acceptCookie }) => {
  return (
    <div className='w-full bg-[#4B5563] flex items-center justify-center'>
        <div className='w-full p-4 max-w-[1400px] flex flex-col md:flex-row gap-4'>
            <p className='text-white text-xs md:pr-12'>
            dresez.com uses cookies and browser storage to save data related to user and its preferences. Also it collects data related to user experience for improving the performance and to deliver the best possible value. By tapping on accept you agree to dresez.com <span onClick={() => navigateTo('terms')} className={`text-[${linkColor}] cursor-pointer`}>terms and conditions</span>. See <span onClick={() => navigateTo('privacy')} className={`text-[${linkColor}] cursor-pointer`}>privacy policy</span>.
            </p>

            <div class="px-3 text-center flex items-center justify-center">
                <button onClick={acceptCookie} id="btn" class="px-8 bg-green-400 hover:bg-green-500 text-white rounded font-bold text-sm shadow-xl whitespace-nowrap h-[40px]">I understand</button>
            </div>
        </div>
    </div>
  )
}

export default injectIntl(CookieBanner)
