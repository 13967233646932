import React, { useRef, useState } from 'react';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import './editorStyles.scss';
import '@draft-js-plugins/hashtag/lib/plugin.css';

const hashtagPlugin = createHashtagPlugin();
const plugins = [hashtagPlugin];

const DescriptionEditor = ({ className, placeholder, seteditorState, editorState }) => {
  const editor = useRef()
  const onChange = (editorState) => {
    seteditorState(editorState)
  };

  const focus = () => {
    editor?.current?.focus();
  };

    return (
      <div className={className} onClick={focus}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          ref={editor}
          placeholder={placeholder}
        />
      </div>
    );
}

export default DescriptionEditor